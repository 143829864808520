import { Cell, Label } from "@res/smart-ui-component-library";
import InsightAnalysis from "../../../components/alerts/insight_analysis/InsightAnalysis";
import { NewInsightAnalysis } from "../../../components/new_insight_analysis/NewInsightAnalysis";
import {
  InsightAnalysisDto,
  InsightDto
} from "../../../repositories/InsightRepo";

interface AnalysisSectionProps {
  theme: "light" | "dark";
  analyses: InsightAnalysisDto[];
  updateAnalysis: (
    analysis: InsightAnalysisDto,
    content: string
  ) => Promise<void>;
  deleteAnalysis: (analysis: InsightAnalysisDto) => Promise<void>;
  submitAnalysis: (content: string) => Promise<void>;
  setWorkingInsight: (insight: InsightDto) => void;
  workingInsight: InsightDto;
}

export default function AnalysisSection({
  theme,
  analyses,
  updateAnalysis,
  deleteAnalysis,
  submitAnalysis
}: Readonly<AnalysisSectionProps>) {
  const zeroAnalyses = analyses.length === 0;
  const containerClassName = `analysis-container ${theme}`;
  const containerStyle = zeroAnalyses ? { minHeight: "75px" } : undefined;

  return (
    <Cell
      wLarge={12}
      wMedium={12}
      wSmall={12}
      style={{ margin: "0.5rem 1.5rem" }}
    >
      <Label htmlFor="">Analysis Notes</Label>
      <div className={containerClassName} style={containerStyle}>
        {analyses.map(analysis => (
          <InsightAnalysis
            key={analysis.id}
            analysis={analysis}
            updateAnalysis={updateAnalysis}
            deleteAnalysis={deleteAnalysis}
          />
        ))}
      </div>
      <NewInsightAnalysis submitAnalysis={submitAnalysis} />
    </Cell>
  );
}
