import { useContext, useEffect, useState } from "react";
import Layout from "../components/Layout";
import { Paper } from "../components/Paper";
import { DiagnosticDashboardContainer } from "../components/ShinyContainer";
import { calculatePriorDateDays } from "../utils/DateUtils";
import { AnalyticContext } from "../contexts/AnalyticContext";
import {
  PrimaryButton,
  Select,
  SelectOption
} from "@res/smart-ui-component-library";
import { AnalyticGroupConfig } from "../models/AnalyticGroupConfig";
import { SiteShortConfig } from "../models/SiteShortConfig";
import SelectSite from "../components/nav/SelectSite";
import { SelectedSiteContext } from "../contexts/SelectedSiteContext";
import {
  EXPLORER_DASHBOARD,
  DCHEALTH_DASHBOARD,
  getDashboard
} from "../dashboardConfig";
import { Dashboard } from "../models/Dashboard";

const unavailableAnalyticGroups = ["yaw_misalignment"];

const filterAnalyticGroups = (
  solarAnalyticGroups: AnalyticGroupConfig[],
  windAnalyticGroups: AnalyticGroupConfig[],
  selectedSite: SiteShortConfig | undefined,
  unavailableAnalyticGroups: string[]
) => {
  const relevantAnalyticGroups = selectedSite?.isSolar
    ? solarAnalyticGroups
    : windAnalyticGroups;

  return relevantAnalyticGroups.filter(
    group => !unavailableAnalyticGroups.includes(group.groupId)
  );
};

const EXPLORER: SelectOption = {
  value: EXPLORER_DASHBOARD,
  label: "Data Explorer"
};

const DCHEALTH: AnalyticGroupConfig = {
  groupId: DCHEALTH_DASHBOARD,
  groupName: "DC Health"
};

const PLACEHOLDER: SelectOption = {
  value: "",
  label: "Select..."
};

export default function Explore() {
  const [selectedSite, setSelectedSite] = useContext(SelectedSiteContext);
  const { solarAnalyticGroups, windAnalyticGroups } = useContext(
    AnalyticContext
  );

  const relevantAnalyticGroups = filterAnalyticGroups(
    [DCHEALTH].concat(solarAnalyticGroups),
    windAnalyticGroups,
    selectedSite,
    unavailableAnalyticGroups
  );

  const [selectedAnalyticGroup, setSelectedAnalyticGroup] = useState<
    SelectOption | undefined
  >(PLACEHOLDER);
  const [selectedDashboard, setSelectedDashboard] = useState<Dashboard | null>(
    null
  );
  const [selectedDashboardId, setSelectedDashboardId] = useState<
    string | undefined
  >(undefined);

  const siteId = selectedSite?.siteId;

  // when site changes, set the dashboard to explorer dashboard
  useEffect(() => {
    if (selectedSite?.siteId) {
      setSelectedAnalyticGroup(PLACEHOLDER);
      setSelectedDashboardId(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSite?.siteId]);

  // when analytic group changes, set the dashboard to the dashboard for that group
  useEffect(() => {
    if (selectedAnalyticGroup?.value && selectedSite) {
      updateDashboard(selectedSite, selectedAnalyticGroup.value.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAnalyticGroup]);

  // Retrieve the dashboard for the given analytic group and set the selected dashboard accordingly
  // If the dashboard has options, set the selected dashboard id to the first option
  // Otherwise, set the selected dashboard id to the dashboard's id

  const startDate = calculatePriorDateDays(7).toISOString();
  const endDate = new Date().toISOString();

  const updateDashboard = (
    selectedSite: SiteShortConfig,
    analyticGroupId: string
  ) => {
    const newSelectedDashboard = getDashboard(selectedSite, analyticGroupId);
    setSelectedDashboard(newSelectedDashboard);

    if (newSelectedDashboard.hasOptions()) {
      setSelectedDashboardId(undefined);
    } else {
      setSelectedDashboardId(
        `${newSelectedDashboard.getDashboardId()}/?asset_id=${siteId}&alert_start=${startDate}&alert_end=${endDate}`
      );
    }
  };

  const noSiteSelected = selectedSite === undefined;

  // TEMPORARY LOGIC WHILE DASHBOARDS ARE BEING UPDATED
  return (
    <Layout pageName="Explore">
      {noSiteSelected && (
        <Paper>
          <div style={{ marginLeft: "1.5rem" }}>
            <SelectSite
              selectedSite={selectedSite}
              setSelectedSite={setSelectedSite}
            />
          </div>
        </Paper>
      )}

      {siteId && (
        <Paper>
          <div style={{ marginLeft: "1.5rem", display: "flex", gap: "1rem" }}>
            <SelectSite
              selectedSite={selectedSite}
              setSelectedSite={setSelectedSite}
            />
            <AnalyticGroupFilter
              selectedAnalyticGroup={selectedAnalyticGroup}
              setSelectedAnalyticGroup={setSelectedAnalyticGroup}
              relevantAnalyticGroups={relevantAnalyticGroups}
            />
            {selectedDashboard && selectedDashboard.hasOptions() && (
              <DashboardVariantFilter
                selectedDashboard={selectedDashboard}
                selectedDashboardId={selectedDashboardId}
                setSelectedDashboardId={setSelectedDashboardId}
              />
            )}
          </div>

          <div
            data-testid={
              selectedSite?.siteId
                ? `${selectedSite.siteId}-diagnostic-dashboard`
                : ""
            }
          >
            {selectedDashboardId && (
              <>
                <a
                  href={selectedDashboardId}
                  target="_blank"
                  rel="noreferrer"
                  style={{ display: "block", marginTop: "1rem" }}
                >
                  <PrimaryButton onClick={() => {}}>
                    Open Dashboard
                  </PrimaryButton>
                </a>
                <DiagnosticDashboardContainer url={selectedDashboardId} />
              </>
            )}
          </div>
        </Paper>
      )}
    </Layout>
  );
}

interface AnalyticGroupFilterProps {
  selectedAnalyticGroup: SelectOption | undefined;
  setSelectedAnalyticGroup: (newAnalyticGroup: SelectOption) => void;
  relevantAnalyticGroups: AnalyticGroupConfig[];
}

const AnalyticGroupFilter = ({
  selectedAnalyticGroup,
  setSelectedAnalyticGroup,
  relevantAnalyticGroups
}: AnalyticGroupFilterProps) => {
  const options = [EXPLORER].concat(
    relevantAnalyticGroups.map(a => ({
      value: a.groupId,
      label: a.groupName
    }))
  );
  return (
    <Select
      label="Dashboard Type"
      aria-label={"Select Type"}
      value={selectedAnalyticGroup}
      name="analytic-group"
      options={options}
      onChange={(newSelectedAnalyticGroup: SelectOption) => {
        setSelectedAnalyticGroup(newSelectedAnalyticGroup);
      }}
      className="dropdown"
      placeholder="Select..."
    />
  );
};

interface DashboardVariantFilterProps {
  selectedDashboard: Dashboard;
  selectedDashboardId: string | undefined;
  setSelectedDashboardId: (newDashboardId: string) => void;
}

const DashboardVariantFilter = ({
  selectedDashboard,
  selectedDashboardId,
  setSelectedDashboardId
}: DashboardVariantFilterProps) => {
  const dashboardOptions = selectedDashboard.getDashboardOptions();

  const options = dashboardOptions.map(option => ({
    value: option.dashboardId,
    label: option.label
  }));

  // Only assign the value if selectedDashboardId is not null
  const value = selectedDashboardId
    ? {
        value: selectedDashboardId,
        label: dashboardOptions.filter(
          option => option.dashboardId === selectedDashboardId
        )[0].label
      }
    : null;

  return (
    <Select
      label="Variant"
      aria-label={"Select Variant"}
      value={value}
      name="dashboard-variant"
      options={options}
      onChange={(newSelectedDashboardId: SelectOption) => {
        setSelectedDashboardId(newSelectedDashboardId.value.toString());
      }}
      className="dropdown"
    />
  );
};
