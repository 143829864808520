import { useMsal } from "@azure/msal-react";
import { InsightAnalysisDto } from "../../../repositories/InsightRepo";
import { TextButton } from "@res/smart-ui-component-library";
import { parseISO } from "date-fns";
import "./InsightAnalysis.scss";
import { useEffect, useState } from "react";
import LoadingSpinner from "../../layout/LoadingSpinner";
import {
  EditorState,
  RawDraftContentState,
  convertFromRaw,
  convertToRaw
} from "draft-js";
import RichTextEditor from "../../rich_text_editor/RichTextEditor";
import { uniqueId } from "lodash";

const formatTimestamp = (timestamp: string) => {
  return parseISO(timestamp).toLocaleDateString(undefined, {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit"
  });
};

function analysisContentToEditorState(content: string): EditorState {
  return EditorState.createWithContent(convertFromRaw(JSON.parse(content)));
}

function editorStateToAnalysisContent(editorState: EditorState): string {
  return JSON.stringify(convertToRaw(editorState.getCurrentContent()));
}

interface InsightAnalysisProps {
  analysis: InsightAnalysisDto;
  updateAnalysis: (
    analysis: InsightAnalysisDto,
    content: string
  ) => Promise<void>;
  deleteAnalysis: (analysis: InsightAnalysisDto) => Promise<void>;
}

export default function InsightAnalysis({
  analysis,
  updateAnalysis,
  deleteAnalysis
}: InsightAnalysisProps) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const userId = useMsal().accounts[0]?.idTokenClaims?.oid;
  const isMyAnalysis = userId === analysis.userIdCreated;
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const [content, setContent] = useState<RawDraftContentState>({
    blocks: [],
    entityMap: {}
  });
  const [editorKey, setEditorKey] = useState<string>("");

  useEffect(() => {
    setEditorState(analysisContentToEditorState(analysis.content));
    setContent(JSON.parse(analysis.content));
    setEditorKey(uniqueId());
  }, [analysis.content]);

  return (
    <div
      role="group"
      aria-label="Analysis"
      key={analysis.id}
      className="analysis-history-item"
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <RichTextEditor
          key={editorKey}
          editorClassName="analysis-text"
          initialContentState={content}
          readOnly={!isEditMode}
          onEditorStateChange={s => setEditorState(s)}
          wrapperClassName="analysis-text-wrapper"
          toolbarClassName="analysis-text-toolbar"
        />
        {isEditMode && (
          <>
            {!isSaving && (
              <>
                <TextButton
                  className="analysis-save-discard"
                  icon="check"
                  onClick={async () => {
                    setIsSaving(true);
                    await updateAnalysis(
                      analysis,
                      editorStateToAnalysisContent(editorState)
                    );
                    setIsEditMode(false);
                    setIsSaving(false);
                  }}
                />
                <TextButton
                  className="analysis-save-discard"
                  icon="times"
                  onClick={() => {
                    setEditorState(
                      analysisContentToEditorState(analysis.content)
                    );
                    setIsEditMode(false);
                    setEditorKey(uniqueId());
                  }}
                />
              </>
            )}
            {isSaving && <LoadingSpinner showText={false} />}
          </>
        )}
      </div>
      <div aria-label="analysis metadata" className="analysis-metadata">
        <div>
          <span>
            {analysis.userNameCreated} -{" "}
            {formatTimestamp(analysis.timestampCreated)}
          </span>
          {analysis.timestampCreated !== analysis.timestampModified && (
            <span>
              {" "}
              *(
              {analysis.userNameModified} -{" "}
              {formatTimestamp(analysis.timestampModified)})
            </span>
          )}
        </div>
        {!isSaving && (
          <>
            <TextButton
              className="edit-button"
              icon="pen"
              onClick={() => setIsEditMode(true)}
            />
            {isMyAnalysis && (
              <TextButton
                className="delete-button"
                role="button"
                aria-label="Delete comment"
                onClick={async () => {
                  setIsSaving(true);
                  await deleteAnalysis(analysis);
                  setIsSaving(false);
                }}
              >
                <svg
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </TextButton>
            )}
          </>
        )}
        {isSaving && (
          <LoadingSpinner className="loading-spinner" showText={false} />
        )}
      </div>
    </div>
  );
}
