import { AnalyticConfig } from "./../models/AnalyticConfig";
import { AnalyticGroupConfig } from "./../models/AnalyticGroupConfig";
import uniqBy from "lodash/uniqBy";

export const getUniqueAnalyticGroups = (
  analytics: AnalyticConfig[]
): AnalyticGroupConfig[] => {
  return uniqBy(analytics, a => a.analyticGroup).map(a => {
    return {
      groupId: a.analyticGroup,
      groupName: a.analyticGroupName
    } as AnalyticGroupConfig;
  });
};
