import {
  PrimaryButton,
  SolarTable,
  SolarTableColumn,
  SolarTableItem
} from "@res/smart-ui-component-library";
import Layout from "../components/Layout";
import { Paper } from "../components/Paper";
import { useContext, useEffect, useState } from "react";
import { InsightRepoContext } from "../contexts/InsightRepoContext";
import { InsightDto } from "../repositories/InsightRepo";
import { usePortfolio } from "../hook/usePortfolioSites";
import { SelectedPortfolioContext } from "../contexts/SelectedPortfolioContext";
import { Portfolio } from "../models/Portfolio";
import { TechnologyIcon } from "../components/technology/TechnologyIcon";
import { uniq } from "lodash";
import { CreateInsightButton } from "../components/create_insight_button/CreateInsightButton";

export function Insights() {
  const insightRepo = useContext(InsightRepoContext);
  const { selectedPortfolio } = useContext(SelectedPortfolioContext);
  const portfolio = usePortfolio(selectedPortfolio?.id);
  const [insights, setInsights] = useState<InsightDto[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const siteLookup: Record<string, Portfolio["sites"][number]> =
    portfolio?.sites?.reduce(
      (acc, next) => ({ ...acc, [next.siteId]: next }),
      {}
    ) ?? {};

  const technologyOptions = [
    { value: "solar", label: "Solar" },
    { value: "wind", label: "Wind" }
  ];

  const columns: SolarTableColumn[] = [
    {
      name: "technology",
      title: "Technology",
      renderItem: (technology: string) => (
        <TechnologyIcon technology={technology} />
      ),
      width: 100,
      filterBy: "dropdown",
      filterByDropdownLogic: "includeAny",
      filterByOptions: technologyOptions
    },
    {
      name: "asset",
      title: "Asset",
      sortBy: "string",
      filterBy: "dropdown",
      filterByDropdownLogic: "includeAny",
      filterByOptions: uniq(
        insights.map(i => siteLookup[i.siteId]?.siteName)
      ).map(s => ({ value: s, label: s }))
    },
    {
      name: "id",
      title: "ID",
      width: 100,
      sortBy: "string",
      filterBy: "string"
    },
    {
      name: "title",
      title: "Title",
      sortBy: "string",
      filterBy: "string"
    },
    {
      name: "status",
      title: "Status",
      width: 100,
      sortBy: "string",
      filterBy: "dropdown",
      filterByDropdownLogic: "includeAny",
      filterByOptions: uniq(insights.map(i => i.status)).map(s => ({
        value: s,
        label: s
      }))
    },
    {
      name: "timestampCreated",
      title: "Created Date",
      renderItem: (item: SolarTableItem) => {
        const timestampStart = item as Date;
        return (
          <>
            {timestampStart.toLocaleDateString(undefined, {
              day: "2-digit",
              month: "short",
              year: "numeric"
            })}
          </>
        );
      },
      sortBy: "date",
      filterBy: "date",
      filterHelpText: "Filter by date",
      width: 100
    },
    {
      name: "view",
      title: "",
      width: 100,
      renderItem: (id: string) => (
        <a
          href={`/insights/insight/${encodeURIComponent(id)}`}
          target="_blank"
          rel="noreferrer"
          style={{ display: "block" }}
        >
          <PrimaryButton onClick={() => {}} useUnoStyling>
            View
          </PrimaryButton>
        </a>
      )
    }
  ];

  const items = insights.map(i => [
    siteLookup[i.siteId]?.technology?.toLowerCase() ?? "",
    siteLookup[i.siteId]?.siteName ?? "",
    i.id,
    i.title,
    i.status,
    new Date(i.timestampCreated),
    i.id
  ]);

  useEffect(() => {
    setInsights([]);
    if (!portfolio?.sites?.length) return;

    setIsLoading(true);

    insightRepo
      .listInsights(portfolio?.sites?.map(s => s.siteId))
      .then(insights => {
        setInsights(insights);
        setIsLoading(false);
      });
  }, [insightRepo, portfolio]);

  return (
    <Layout pageName="Insights">
      <Paper>
        {portfolio?.id ? (
          <>
            {portfolio?.sites?.length !== 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: "1rem"
                }}
              >
                <CreateInsightButton />
              </div>
            )}
            <SolarTable
              columns={columns}
              items={items}
              enableSiteTimeZone={false}
              siteTimeZone={""}
              isLoading={isLoading}
              loadingText="Loading insights, please wait..."
            />
          </>
        ) : (
          <h1 style={{ textAlign: "center" }}>No Portfolio selected</h1>
        )}
      </Paper>
    </Layout>
  );
}
