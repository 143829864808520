import { StatusIndicator } from "../../models/StatusIndicator";
import { RedIndicator } from "./red/RedIndicator";
import { AmberIndicator } from "./amber/AmberIndicator";
import { GreenIndicator } from "./green/GreenIndicator";
import { UnknownIndicator } from "./unknown/UnknownIndicator";

export function Indicator({
  status,
  withTooltip
}: {
  status: StatusIndicator;
  withTooltip: boolean;
}) {
  switch (status) {
    case StatusIndicator.Red:
      return <RedIndicator withTooltip={withTooltip} />;
    case StatusIndicator.Amber:
      return <AmberIndicator withTooltip={withTooltip} />;
    case StatusIndicator.Green:
      return <GreenIndicator withTooltip={withTooltip} />;
  }
  return <UnknownIndicator withTooltip={withTooltip} />;
}
