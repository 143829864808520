import { Token, tokenFromResponse } from "../models/Token";
import axios from "axios";

export interface TokenRepository {
  getTableauToken(): Promise<Token>;
}

export class InMemoryTokenRepository implements TokenRepository {
  private readonly token: Token;

  constructor(token: Token) {
    this.token = token;
  }

  getTableauToken(): Promise<Token> {
    return Promise.resolve(this.token);
  }
}

export class HttpTokenRepository implements TokenRepository {
  private readonly baseUrl: string;
  private readonly getAuthToken: () => Promise<string | undefined>;

  constructor(
    baseUrl: string,
    getAuthToken: () => Promise<string | undefined>
  ) {
    this.baseUrl = baseUrl;
    this.getAuthToken = getAuthToken;
  }

  async getTableauToken(): Promise<Token> {
    let url = `${this.baseUrl}/api/token/tableau`;

    const fetchResponse = await axios.get(url, {
      headers: { Authorization: `Bearer ${await this.getAuthToken()}` },
      params: {}
    });
    const json = await fetchResponse.data;
    return tokenFromResponse(json);
  }
}
