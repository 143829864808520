import { useContext, useEffect, useMemo, useState } from "react";
import Layout from "../components/Layout";
import { SelectedSiteContext } from "../contexts/SelectedSiteContext";
import { useStatusList } from "../hook/useStatusList";
import { Paper } from "../components/Paper";
import { CreateHeatmap } from "../components/Heatmap";
import { format } from "date-fns";
import LoadingSpinner from "../components/layout/LoadingSpinner";
import NoDataFoundText from "../components/layout/NoDataFoundText";
import { AnalyticContext } from "../contexts/AnalyticContext";
import { AnalyticConfig } from "../models/AnalyticConfig";
import { Select, SelectOption } from "@res/smart-ui-component-library";
import { calculatePriorDate } from "../utils/DateUtils";
import { AlertRepoContext } from "../contexts/AlertRepoContext";
import {
  compareAssetIds,
  sortAssetListByAssetType,
  sortStatusListByAssetType
} from "../utils/SortUtils";
import { AssetConfig } from "../models/SiteConfig";
import { Status } from "../models/Status";
import SelectSite from "../components/nav/SelectSite";
import { SelectedPortfolioContext } from "../contexts/SelectedPortfolioContext";
import { usePortfolio } from "../hook/usePortfolioSites";
import { SiteShortConfig } from "../models/SiteShortConfig";

const formatDate = (date: Date) => {
  return format(date, "yyyy-MM-dd'T00:00:00+00:00'");
};

const ALL_ANALYTICS: SelectOption = { value: "All", label: "All" };

export default function Statuses() {
  const { solarAnalytics, windAnalytics } = useContext(AnalyticContext);
  const [selectedSite, setSelectedSite] = useContext(SelectedSiteContext);
  const { selectedPortfolio } = useContext(SelectedPortfolioContext);
  const portfolio = usePortfolio(selectedPortfolio?.id);
  const alertsRepo = useContext(AlertRepoContext);

  const relevantAnalytics = selectedSite?.isSolar
    ? solarAnalytics
    : windAnalytics;

  const startDate = calculatePriorDate(3);
  const timestampStart = formatDate(startDate);
  const { statuses, isLoading } = useStatusList(
    selectedSite?.siteId,
    undefined,
    undefined,
    timestampStart
  );

  const [selectedAnalytic, setSelectedAnalytic] = useState<SelectOption>(
    ALL_ANALYTICS
  );
  const [assets, setAssets] = useState<AssetConfig[]>([]);

  useEffect(() => {
    if (!portfolio) {
      setSelectedSite(undefined);
      return;
    }

    const site = new SiteShortConfig(
      portfolio.sites[0].siteName,
      portfolio.sites[0].siteId,
      portfolio.sites[0].technology
    );

    setSelectedSite(site);
  }, [portfolio, setSelectedSite]);

  useEffect(() => {
    if (selectedSite?.siteId) {
      setSelectedAnalytic(ALL_ANALYTICS);
    }
  }, [selectedSite?.siteId]);

  useEffect(() => {
    (async () => {
      if (!selectedSite) {
        setAssets([]);
        return;
      }

      alertsRepo.getSite(selectedSite.siteId).then(siteConfig => {
        if (!siteConfig) {
          setAssets([]);
          return;
        }
        const newAssets = [...siteConfig.objectConfigs]
          .sort((a, b) => compareAssetIds(a.objectId, b.objectId))
          .map(object => ({ ...object, siteId: siteConfig.siteId }));

        setAssets(sortAssetListByAssetType(selectedSite, newAssets));
      });
    })();
  }, [alertsRepo, selectedSite]);

  const filteredStatuses = useMemo(() => {
    if (statuses.length === 0) return statuses;
    if (selectedSite === undefined) return statuses;

    if (selectedAnalytic === undefined) return statuses;

    if (selectedAnalytic.value === ALL_ANALYTICS.value) return statuses;

    const filteredStatuses = statuses.filter(
      status => status.analyticId === selectedAnalytic.value
    );

    return filteredStatuses;
  }, [selectedSite, statuses, selectedAnalytic]);

  const sortedStatuses = useMemo(() => {
    if (filteredStatuses.length === 0) return filteredStatuses;
    if (selectedSite === undefined) return filteredStatuses;
    if (assets.length === 0) return filteredStatuses;

    const sortedStatuses: Status[] = sortStatusListByAssetType(
      assets,
      filteredStatuses
    );

    return sortedStatuses;
  }, [selectedSite, filteredStatuses, assets]);

  const isDataLoading = selectedSite && isLoading;
  const noSiteSelected = selectedSite === undefined;
  const loaded = !isLoading;
  const noDataFound = loaded && filteredStatuses.length === 0;

  console.log("statuses render", {
    site: selectedSite ?? null,
    portfolio,
    analytic: selectedAnalytic.label,
    statuses,
    filteredStatuses,
    sortedStatuses
  });

  return (
    <Layout pageName="Status History">
      {(isDataLoading || noSiteSelected) && (
        <Paper>
          {isDataLoading && <LoadingSpinner />}
          {noSiteSelected && (
            <div style={{ marginLeft: "1.5rem" }}>
              <SelectSite
                selectedSite={selectedSite}
                setSelectedSite={setSelectedSite}
              />
            </div>
          )}
        </Paper>
      )}

      {loaded && (
        <Paper hideBottomPadding>
          <div style={{ marginLeft: "1.5rem", display: "flex", gap: "1rem" }}>
            <SelectSite
              selectedSite={selectedSite}
              setSelectedSite={setSelectedSite}
            />
            <AnalyticFilter
              selectedAnalytic={selectedAnalytic}
              setSelectedAnalytic={setSelectedAnalytic}
              relevantAnalytics={relevantAnalytics}
            />
          </div>

          {noDataFound && <NoDataFoundText />}
          <CreateHeatmap
            statuses={sortedStatuses}
            startXAxis={startDate.getTime()}
          />
        </Paper>
      )}
    </Layout>
  );
}

interface AnalyticFilterProps {
  selectedAnalytic: SelectOption;
  setSelectedAnalytic: (newAnalytic: SelectOption) => void;
  relevantAnalytics: AnalyticConfig[];
}

const AnalyticFilter = ({
  selectedAnalytic,
  setSelectedAnalytic,
  relevantAnalytics
}: AnalyticFilterProps) => {
  const options = [ALL_ANALYTICS].concat(
    relevantAnalytics.map(a => ({
      value: a.analyticId,
      label: a.analyticName
    }))
  );
  return (
    <Select
      label="Analytic"
      aria-label={"Select Analytic"}
      value={selectedAnalytic}
      name="analytic"
      options={options}
      onChange={(newSelectedAnalytic: SelectOption) => {
        setSelectedAnalytic(newSelectedAnalytic);
      }}
      className="dropdown"
      placeholder="Select Analytic..."
    />
  );
};
