import { useCallback, useContext, useEffect, useState } from "react";
import { Alert } from "../models/Alert";
import { AlertRepoContext } from "../contexts/AlertRepoContext";
import { usePortfolio } from "./usePortfolioSites";

interface AlertListResponse {
  alerts: Alert[] | undefined;
  isLoading: boolean;
}

export function useAlertList(
  selectedPortfolio?: string,
  isOpen?: boolean
): AlertListResponse {
  const [alerts, setAlerts] = useState<undefined | Alert[]>();
  const [isLoading, setIsLoading] = useState(true);
  const repo = useContext(AlertRepoContext);
  const portfolio = usePortfolio(selectedPortfolio);

  const loadAlerts = useCallback(
    async ({ isInitialLoad }: { isInitialLoad: boolean }) => {
      setAlerts(undefined);
      if (!selectedPortfolio) {
        return;
      }

      isInitialLoad && setIsLoading(true);

      if (!portfolio) {
        return;
      }

      if (!portfolio.sites?.length) {
        isInitialLoad && setIsLoading(false);
        setAlerts(undefined);
        return;
      }

      const siteIds = portfolio.sites.map(s => s.siteId);

      setAlerts(await repo.listAlerts({ siteIds, isOpen }));
      isInitialLoad && setIsLoading(false);
    },
    [selectedPortfolio, repo, isOpen, portfolio]
  );

  useEffect(() => {
    loadAlerts({ isInitialLoad: true });

    const pollEveryMinute = 60000;
    const pollEveryHour = 60 * pollEveryMinute;

    const poller = setInterval(
      () => loadAlerts({ isInitialLoad: false }),
      pollEveryHour
    );

    return () => clearInterval(poller);
  }, [loadAlerts]);

  return { alerts, isLoading };
}
