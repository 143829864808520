import { Cell, Card } from "@res/smart-ui-component-library";
import React from "react";
import "./Paper.scss";

interface PaperProps extends React.PropsWithChildren<{}> {
  hideBottomPadding?: boolean;
  hideTopPadding?: boolean;
}

export function Paper({
  children,
  hideBottomPadding = false,
  hideTopPadding = false
}: PaperProps) {
  return (
    <>
      <Cell>
        <Card
          className={`paper-card ${
            hideBottomPadding ? "no-bottom-padding" : ""
          } ${hideTopPadding ? "no-top-padding" : ""}`}
          header={""}
        >
          {children}
        </Card>
      </Cell>
    </>
  );
}
