interface TableauEvent extends Event {
  detail?: LoadError;
}

interface LoadError {
  _message: string;
}

interface LoadErrorResponse {
  errorMessage: string;
}

export const retrieveTableauErrorCode = (event: Event): number | undefined => {
  try {
    const tableauEvent = event as TableauEvent;
    const loadError = tableauEvent?.detail as LoadError;
    const errorResponse = JSON.parse(loadError._message) as LoadErrorResponse;
    const errorMessage = errorResponse.errorMessage;

    const errorResultObject = JSON.parse(errorMessage);
    const result = errorResultObject.result;
    return result.errors[0].code;
  } catch (err) {
    console.error(err);
    return undefined;
  }
};
