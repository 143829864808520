import { useState, useContext, useEffect } from "react";
import { AlertRepoContext } from "../contexts/AlertRepoContext";
import { Portfolio } from "../models/Portfolio";

export function usePortfolio(portfolioId?: string): Portfolio | undefined {
  const alertRepo = useContext(AlertRepoContext);
  const [portfolio, setPortfolio] = useState<Portfolio | undefined>();

  useEffect(() => {
    if (portfolioId == null) {
      return;
    }

    const cached = portfolioCacheLookup(portfolioId);
    if (cached) {
      setPortfolio(cached);
      return;
    }

    alertRepo.getPortfolio(portfolioId).then(portfolio => {
      if (!portfolio) {
        return;
      }
      setPortfolio(portfolio);
      portfolioCacheWrite(portfolio);
    });
  }, [portfolioId, alertRepo, portfolio]);

  if (portfolio && portfolio.id === portfolioId) {
    return portfolio;
  } else {
    return undefined;
  }
}

interface PortfolioCacheEntry {
  portfolio: Portfolio;
  expiry: number;
}

function getPortfolioCache(): Record<string, PortfolioCacheEntry | undefined> {
  const key = "portfolio-cache-f391fb12-92d1-40d5-968a-524ba3418c1f";
  const globalThat = globalThis as any;
  return globalThat[key] ?? (globalThat[key] = {});
}

function portfolioCacheKey(portfolioId: string): string {
  return `portfolio-${portfolioId}`;
}

function portfolioCacheLookup(portfolioId: string): Portfolio | undefined {
  const cache = getPortfolioCache();
  const entry = cache[portfolioCacheKey(portfolioId)];
  if (!entry || !entry.expiry || !entry.portfolio) {
    return undefined;
  }

  const now = new Date().getTime();
  if (entry.expiry < now) {
    return undefined;
  }

  return entry.portfolio;
}

function portfolioCacheWrite(portfolio: Portfolio): void {
  const now = new Date().getTime();
  const expiry = now + 30 * 60 * 1000; // 30 minutes from now
  const entry: PortfolioCacheEntry = { portfolio, expiry };
  const cache = getPortfolioCache();
  cache[portfolioCacheKey(portfolio.id)] = entry;
}
