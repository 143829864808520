import { Status, statusFromResponse } from "../models/Status";
import axios from "axios";

export type StatusSortBy = "None" | "DurationAscending" | "DurationDescending";
export interface StatusRepository {
  listStatuses(
    selectedSite: string,
    sortBy?: StatusSortBy,
    isOpen?: boolean,
    timestampStart?: string
  ): Promise<Status[]>;
}

export class InMemoryStatusRepository implements StatusRepository {
  private readonly statuses: Record<string, Record<string, Status>>;

  constructor(statuses: Record<string, Status[]>) {
    let morphedStatuses: Record<string, Record<string, Status>> = {};

    for (const siteId in statuses) {
      let siteStatuses = statuses[siteId];
      morphedStatuses[siteId] = {};

      for (let statusHash in siteStatuses) {
        let siteStatus = siteStatuses[statusHash];
        morphedStatuses[siteId][siteStatus.statusHash || ""] = siteStatus;
      }
    }

    this.statuses = morphedStatuses;
  }

  listStatuses(
    selectedSite: string,
    sortBy?: StatusSortBy,
    isOpen?: boolean,
    timestampStart?: string
  ): Promise<Status[]> {
    let statuses = Object.entries(this.statuses)
      .filter(([key]) => key === selectedSite)
      .flatMap(([, value]) => Object.values(value))
      .filter(status => isOpen == null || status.isActive === isOpen)
      .filter(
        status =>
          timestampStart === undefined ||
          status.timestampStart > new Date(timestampStart)
      );

    if (sortBy === "DurationAscending") {
      statuses.sort((a, b) => a.durationMilliseconds - b.durationMilliseconds);
    }

    if (sortBy === "DurationDescending") {
      statuses.sort((a, b) => b.durationMilliseconds - a.durationMilliseconds);
    }

    return Promise.resolve(statuses);
  }
}

export class HttpStatusRepository implements StatusRepository {
  private readonly baseUrl: string;
  private readonly getAuthToken: () => Promise<string | undefined>;

  constructor(
    baseUrl: string,
    getAuthToken: () => Promise<string | undefined>
  ) {
    this.baseUrl = baseUrl;
    this.getAuthToken = getAuthToken;
  }

  async listStatuses(
    siteId: string,
    sortBy?: StatusSortBy,
    isOpen?: boolean,
    timestampStart?: string
  ): Promise<Status[]> {
    let url = `${this.baseUrl}/api/status`;

    const fetchResponse = await axios.get(url, {
      headers: { Authorization: `Bearer ${await this.getAuthToken()}` },
      params: { siteId, sortBy, isOpen, timestampStart }
    });
    const json = await fetchResponse.data;

    return json.statuses.map(statusFromResponse);
  }
}
