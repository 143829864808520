import React, { PropsWithChildren } from "react";
import "./App.scss";
import { Route, Routes } from "react-router-dom";
import About from "./pages/About";
import Explore from "./pages/Explore";
import Alerts from "./pages/Alerts";
import Redirect from "./pages/Redirect";

import Statuses from "./pages/Statuses";
import { CurrentStatus } from "./pages/CurrentStatus";
import AlertActions from "./pages/AlertActions";
import ContextWrapper from "./contexts/ContextWrapper";
import Insight from "./pages/insight/Insight";
import { Insights } from "./pages/Insights";

function App({
  authNavProvider
}: {
  authNavProvider?: React.ElementType<PropsWithChildren<unknown>>;
}) {
  const Auth = authNavProvider || React.Fragment;

  return (
    <Auth>
      <Routes>
        <Route index element={<Redirect to="/alerts" />} />

        <Route
          path="alerts/site?/:site?"
          element={
            <ContextWrapper>
              <Alerts />
            </ContextWrapper>
          }
        />
        <Route
          path="alerts/site/:site/alert/:alertId"
          element={
            <ContextWrapper>
              <AlertActions />
            </ContextWrapper>
          }
        />
        <Route
          path="latest-status/site?/:site?"
          element={
            <ContextWrapper>
              <CurrentStatus />
            </ContextWrapper>
          }
        />
        <Route
          path="status-history/site?/:site?"
          element={
            <ContextWrapper>
              <Statuses />
            </ContextWrapper>
          }
        />
        <Route
          path="explore/site?/:site?"
          element={
            <ContextWrapper>
              <Explore />
            </ContextWrapper>
          }
        />
        <Route
          path="insights"
          element={
            <ContextWrapper>
              <Insights />
            </ContextWrapper>
          }
        />
        <Route
          path="insights/site?/:site?/insight/:insightId?"
          element={
            <ContextWrapper>
              <Insight />
            </ContextWrapper>
          }
        />
        <Route
          path="about"
          element={
            <ContextWrapper>
              <About />
            </ContextWrapper>
          }
        />
      </Routes>
    </Auth>
  );
}

export default App;
