import { Cell, FilterTable, Tooltip } from "@res/smart-ui-component-library";
import { useContext, useEffect, useState, useMemo } from "react";
import { Indicator } from "../components/indicators/Indicator";
import Layout from "../components/Layout";
import { Paper } from "../components/Paper";
import { SelectedSiteContext } from "../contexts/SelectedSiteContext";
import { useStatusList } from "../hook/useStatusList";
import { Status } from "../models/Status";
import { AlertRepoContext } from "../contexts/AlertRepoContext";
import { AssetConfig } from "../models/SiteConfig";
import { getObjectName } from "../utils/AlertStatusUtils";
import { AnalyticGroupConfig } from "./../models/AnalyticGroupConfig";
import "./CurrentStatus.scss";
import { SiteShortConfig } from "../models/SiteShortConfig";
import { AnalyticContext } from "./../contexts/AnalyticContext";
import LoadingSpinner from "../components/layout/LoadingSpinner";
import NoDataFoundText from "../components/layout/NoDataFoundText";
import { getUniqueAnalyticGroups } from "./../utils/AnalyticUtils";
import { TechnologyIcon } from "../components/technology/TechnologyIcon";
import { compareAssetIds, sortAssetListByAssetType } from "../utils/SortUtils";
import SelectSite from "../components/nav/SelectSite";
import { SelectedPortfolioContext } from "../contexts/SelectedPortfolioContext";
import { usePortfolio } from "../hook/usePortfolioSites";

const generateGrid = (
  assets: AssetConfig[],
  statuses: Status[],
  relevantAnalyticGroups: AnalyticGroupConfig[]
) => {
  const grid: Record<string, Record<string, Status[]>> = {};

  relevantAnalyticGroups.forEach(analyticGroup => {
    grid[analyticGroup.groupId] = {};
    assets.forEach(asset => {
      const relevantStatuses = statuses
        .filter(s => s.analyticGroup === analyticGroup.groupId)
        .filter(s => s.objectId === asset.objectId)
        .sort((a, b) => a.categoryId.localeCompare(b.categoryId));

      grid[analyticGroup.groupId][asset.objectId] = relevantStatuses;
    });
  });

  return grid;
};

const CurrentStatusRow = ({
  asset,
  relevantAnalyticGroups,
  grid,
  site
}: {
  asset: AssetConfig;
  relevantAnalyticGroups: AnalyticGroupConfig[];
  grid: Record<string, Record<string, Status[]>>;
  site: SiteShortConfig;
}) => {
  const getSiteById = useContext(SelectedSiteContext)[2];
  const objectName = getObjectName(asset.objectId, asset.name);

  const siteConfig = getSiteById(asset.siteId);
  const siteName = siteConfig?.siteName;
  const siteTechnology = siteConfig.isSolar ? "solar" : "wind";

  return (
    <FilterTable.BodyRow key={asset.objectId} status="normal">
      <FilterTable.BodyCell key={0}>
        <TechnologyIcon technology={siteTechnology} />
      </FilterTable.BodyCell>
      <FilterTable.BodyCell key={1}>{siteName}</FilterTable.BodyCell>
      <FilterTable.BodyCell key={2}>{objectName}</FilterTable.BodyCell>
      {relevantAnalyticGroups.map(analyticGroup => {
        const statuses = grid[analyticGroup.groupId][asset.objectId];
        const groupHasStatus = statuses.length > 0;
        const worstStatusInGroup = Math.max(
          ...statuses.map(status => status.status)
        );

        const groupStatusTooltipContent = (
          <>
            {statuses.map((status, idx) => {
              return (
                <div key={idx}>
                  <span className="group-status-indicator">
                    <Indicator status={status.status} withTooltip={true} />
                  </span>

                  {status.categoryId}
                </div>
              );
            })}
          </>
        );

        return (
          <FilterTable.BodyCell key={analyticGroup.groupId}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around"
              }}
            >
              {groupHasStatus ? (
                <Tooltip content={groupStatusTooltipContent}>
                  <Indicator status={worstStatusInGroup} withTooltip={false} />
                </Tooltip>
              ) : (
                "-"
              )}
            </div>
          </FilterTable.BodyCell>
        );
      })}
    </FilterTable.BodyRow>
  );
};

export function CurrentStatus() {
  const [selectedSite, setSelectedSite] = useContext(SelectedSiteContext);
  const getSiteById = useContext(SelectedSiteContext)[2];
  const { selectedPortfolio } = useContext(SelectedPortfolioContext);
  const portfolio = usePortfolio(selectedPortfolio?.id);

  const { statuses, isLoading } =
    useStatusList(selectedSite?.siteId, undefined, true) ?? [];
  const alertsRepo = useContext(AlertRepoContext);

  const { analytics: analyticConfigs } = useContext(AnalyticContext);

  const [assets, setAssets] = useState<AssetConfig[]>([]);

  useEffect(() => {
    if (!portfolio) {
      setSelectedSite(undefined);
      return;
    }

    const site = new SiteShortConfig(
      portfolio.sites[0].siteName,
      portfolio.sites[0].siteId,
      portfolio.sites[0].technology
    );

    setSelectedSite(site);
  }, [portfolio, setSelectedSite]);

  useEffect(() => {
    (async () => {
      if (!selectedSite) {
        setAssets([]);
        return;
      }

      alertsRepo.getSite(selectedSite.siteId).then(siteConfig => {
        if (!siteConfig) {
          setAssets([]);
          return;
        }
        const newAssets = [...siteConfig.objectConfigs]
          .sort((a, b) => compareAssetIds(a.objectId, b.objectId))
          .map(object => ({ ...object, siteId: siteConfig.siteId }));

        setAssets(sortAssetListByAssetType(selectedSite, newAssets));
      });
    })();
  }, [alertsRepo, selectedSite]);

  const relevantAnalyticGroups = useMemo(() => {
    if (assets.length === 0) return [];
    const assetTypes = new Set<string>(assets.map(a => a.type));
    const relevantAnalytics = analyticConfigs.filter(a =>
      assetTypes.has(a.associatedAssetType)
    );
    const relevantAnalyticGroups = getUniqueAnalyticGroups(relevantAnalytics);
    return relevantAnalyticGroups;
  }, [analyticConfigs, assets]);

  const grid = generateGrid(assets, statuses, relevantAnalyticGroups);

  const isDataLoading = selectedSite && isLoading;
  const loaded = !isLoading;
  const noDataFound = loaded && statuses.length === 0;
  const loadedDataSuccessfully = loaded && statuses.length > 0;

  return (
    <Layout pageName="Latest Status">
      <Cell>
        <Paper>
          <div style={{ marginLeft: "1.5rem" }}>
            <SelectSite
              selectedSite={selectedSite}
              setSelectedSite={setSelectedSite}
            />
          </div>
          {(isDataLoading || noDataFound) && (
            <>
              {isDataLoading && <LoadingSpinner />}
              {noDataFound && <NoDataFoundText />}
            </>
          )}
          {loadedDataSuccessfully && (
            <FilterTable>
              <FilterTable.Header>
                <FilterTable.BodyRow status="normal">
                  <FilterTable.HeaderCell>Technology</FilterTable.HeaderCell>
                  <FilterTable.HeaderCell>Asset</FilterTable.HeaderCell>
                  <FilterTable.HeaderCell>Device</FilterTable.HeaderCell>
                  {relevantAnalyticGroups.map(analyticGroup => (
                    <FilterTable.HeaderCell
                      key={`${analyticGroup.groupId}__header`}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around"
                        }}
                      >
                        {analyticGroup.groupName}
                      </div>
                    </FilterTable.HeaderCell>
                  ))}
                </FilterTable.BodyRow>
              </FilterTable.Header>
              <FilterTable.Body>
                {assets.map((asset, idx) => {
                  const siteId = asset.siteId;
                  const site = getSiteById(siteId);
                  return (
                    <CurrentStatusRow
                      key={idx}
                      asset={asset}
                      relevantAnalyticGroups={relevantAnalyticGroups}
                      grid={grid}
                      site={site}
                    />
                  );
                })}
              </FilterTable.Body>
            </FilterTable>
          )}
        </Paper>
      </Cell>
    </Layout>
  );
}
