import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router } from "react-router-dom";
// MSAL imports
import { MsalProvider } from "@azure/msal-react";

import { getAccessTokenAlerts, getMsalInstance } from "./auth";
import { MSALNavigationProvider } from "./MSALNavigationProvider";
import { AlertRepoContext } from "./contexts/AlertRepoContext";
import { HTTPAlertRepository } from "./repositories/AlertRepo";
import { StatusRepoContext } from "./contexts/StatusRepoContext";
import { HttpStatusRepository } from "./repositories/StatusRepo";
import { MediaAwareThemeProvider } from "./components/layout/MediaAwareThemeProvider";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./AppInsights";
import { TokenRepoContext } from "./contexts/TokenRepoContext";
import { HttpTokenRepository } from "./repositories/TokenRepo";
import { InsightRepoContext } from "./contexts/InsightRepoContext";
import { HttpInsightRepo } from "./repositories/InsightRepo";

ReactDOM.render(
  <React.StrictMode>
    <MediaAwareThemeProvider>
      <MsalProvider instance={getMsalInstance()}>
        <AppInsightsContext.Provider value={reactPlugin}>
          <HelmetProvider>
            <AlertRepoContext.Provider
              value={
                new HTTPAlertRepository(
                  process.env.REACT_APP_ALERTS_API_BASE_URL || "",
                  getAccessTokenAlerts
                )
              }
            >
              <StatusRepoContext.Provider
                value={
                  new HttpStatusRepository(
                    process.env.REACT_APP_ALERTS_API_BASE_URL || "",
                    getAccessTokenAlerts
                  )
                }
              >
                <TokenRepoContext.Provider
                  value={
                    new HttpTokenRepository(
                      process.env.REACT_APP_ALERTS_API_BASE_URL || "",
                      getAccessTokenAlerts
                    )
                  }
                >
                  <InsightRepoContext.Provider
                    value={
                      new HttpInsightRepo(
                        process.env.REACT_APP_ALERTS_API_BASE_URL || "",
                        getAccessTokenAlerts
                      )
                    }
                  >
                    <Router>
                      <App authNavProvider={MSALNavigationProvider} />
                    </Router>
                  </InsightRepoContext.Provider>
                </TokenRepoContext.Provider>
              </StatusRepoContext.Provider>
            </AlertRepoContext.Provider>
          </HelmetProvider>
        </AppInsightsContext.Provider>
      </MsalProvider>
    </MediaAwareThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
