import logo from "../../media/analytics-logo.svg";
import { Navbar, Select, SelectOption } from "@res/smart-ui-component-library";
import { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./OurNavbar.scss";
import { PortfolioListItem } from "../../models/Portfolio";
import { AlertRepoContext } from "../../contexts/AlertRepoContext";
import { SelectedPortfolioContext } from "../../contexts/SelectedPortfolioContext";

export function OurNavbar() {
  const { selectedPortfolio, setSelectedPortfolio } = useContext(
    SelectedPortfolioContext
  );
  const [portfolios, setPortfolios] = useState<PortfolioListItem[]>([]);
  const alertRepo = useContext(AlertRepoContext);

  useEffect(() => {
    alertRepo.getPortfolios().then(portfolios => setPortfolios(portfolios));
  }, [alertRepo]);

  return (
    <Navbar
      useUnoStyling={true}
      appLogos={
        <NavLink to={"/alerts"} data-text="Homepage">
          <img src={logo} alt="UNO Analytics Logo" className="nav-bar-logo" />
        </NavLink>
      }
      body={
        <Select
          aria-label="Portfolio"
          value={
            selectedPortfolio && {
              value: selectedPortfolio.id,
              label: selectedPortfolio.name
            }
          }
          name="portfolio"
          options={portfolios.map(p => ({
            value: p.id,
            label: p.name
          }))}
          onChange={(e: SelectOption) => {
            setSelectedPortfolio(portfolios.find(p => p.id === e.value));
          }}
          className="dropdown"
          placeholder="Select Portfolio..."
        />
      }
      navLinks={
        <>
          <NavLink className="nav-link" to={"/alerts"} data-text="Alerts">
            Alerts
          </NavLink>
          <NavLink
            className="nav-link"
            to={"/latest-status"}
            data-text="Latest Status"
          >
            Latest Status
          </NavLink>
          <NavLink
            className="nav-link"
            to={"/status-history"}
            data-text="Status History"
          >
            Status History
          </NavLink>
          <NavLink className="nav-link" to="/insights" data-text="Insights">
            Insights
          </NavLink>
          <NavLink className="nav-link" to={"/explore"} data-text="Explore">
            Explore
          </NavLink>
        </>
      }
      ancillaryNavLinks={
        <NavLink className="nav-link" to="/about" data-text="help">
          Help
        </NavLink>
      }
      externalLinks={[
        {
          title: "UNO HUB",
          description:
            "Portfolio view, summary of site activities and report generation",
          url: "https://app.uno-res.com"
        },
        {
          title: "UNO SOLAR",
          description:
            "Key Performance Indicator calculations for solar assets",
          url: "https://app.solar.uno-res.com"
        },
        {
          title: "UNO WIND",
          description: "Key Performance Indicator calculations for wind assets",
          url: "https://app.wind.uno-res.com"
        }
      ]}
      externalLinksButtonStyle={{ fontSize: "1rem", marginTop: "3px" }}
      slideOutLogos={undefined}
      slideOutLinks={[]}
      icons={undefined}
      hideSlideOut={true}
    ></Navbar>
  );
}
