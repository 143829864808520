import { Helmet } from "react-helmet-async";
import React from "react";
import { Stack } from "../components/layout/Stack";

export function Loading() {
  return (
    <>
      <Helmet>
        <title>UNO Analytics - Loading...</title>
      </Helmet>
      <div className={"loading"}>
        <div className="center">
          <Stack direction="horizontal" gap={3}>
            <div className="pulse" role="status"></div>
            <div className="pulse" role="status"></div>
            <div className="pulse" role="status"></div>
          </Stack>
        </div>
      </div>
    </>
  );
}
