import parseISO from "date-fns/parseISO";
import { AlertComment } from "../../models/AlertComment";
import { AlertRepository } from "../../repositories/AlertRepo";
import { InsightDto } from "../../repositories/InsightRepo";
import { TextButton } from "@res/smart-ui-component-library";

export default function InsightComment({
  workingInsight,
  alertRepo,
  userId,
  comment,
  comments,
  setComments,
  index
}: Readonly<{
  workingInsight: InsightDto;
  alertRepo: AlertRepository;
  userId: string | undefined;
  comment: AlertComment;
  comments: AlertComment[];
  setComments: (comments: AlertComment[]) => void;
  index: number;
}>) {
  return (
    <div
      aria-label="Comment"
      key={comment.commentId}
      className={`comment-history-item`}
    >
      <p aria-label="comment text" className="comment-text">
        {comment.content}
      </p>
      <div aria-label="comment metadata" className="comment-metadata">
        <div>
          {comment.userName} -{" "}
          {parseISO(comment.timestampCreated).toLocaleDateString(undefined, {
            day: "2-digit",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit"
          })}
        </div>
        <div>
          {userId === comment.userId && (
            <TextButton
              aria-label={"Delete comment"}
              onClick={e => {
                e.preventDefault();
                alertRepo.deleteComment(
                  workingInsight.siteId,
                  comment.commentId
                );
                setComments(comments.filter((_, id) => id !== index));
              }}
            >
              <svg
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
            </TextButton>
          )}
        </div>
      </div>
    </div>
  );
}
