import React, { useContext } from "react";
import ReactApexChart, { Props } from "react-apexcharts";
import { ThemeContext } from "../contexts/ThemeContext";
import { StatusIndicator } from "../models/StatusIndicator";
import { ApexOptions } from "apexcharts";
import { Status } from "../models/Status";

export function CreateHeatmap(
  props: Props & { statuses?: Status[]; startXAxis: number }
) {
  const statuses = props.statuses || [];
  const startXAxis = props.startXAxis || 0;
  const theme = useContext(ThemeContext);
  const options: ApexOptions = {
    chart: {
      height: 350,
      type: "rangeBar",
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue","sans-serif"'
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "50%",
        rangeBarGroupRows: true
      }
    },
    colors: ["#39792a", "#e87511", "#c62e2e"],
    fill: {
      type: "solid"
    },
    xaxis: {
      type: "datetime",
      labels: {
        style: {
          colors: theme === "dark" ? "var(--res-gray-light)" : "var(--res-gray)"
        }
      },
      min: startXAxis,
      max: Math.max(
        ...statuses.map(
          status => status.timestampEnd?.getTime() || new Date().getTime()
        )
      )
    },
    yaxis: {
      labels: {
        style: {
          colors: theme === "dark" ? "var(--res-gray-light)" : "var(--res-gray)"
        }
      },
      showAlways: true
    },
    legend: {
      labels: {
        colors: theme === "dark" ? "var(--res-gray-light)" : "var(--res-gray)"
      },
      showForSingleSeries: true
    },
    tooltip: { theme }
  };

  const segments = mergeStatuses(statuses);

  const series = [
    {
      name: "Ok",
      data: segments
        .filter(status => status.rag === StatusIndicator.Green)
        .map(status => ({
          x: status.label,
          y: [status.start.getTime(), status.end.getTime()]
        }))
    },
    {
      name: "Monitor",
      data: segments
        .filter(status => status.rag === StatusIndicator.Amber)
        .map(status => ({
          x: status.label,
          y: [status.start.getTime(), status.end.getTime()]
        }))
    },
    {
      name: "Alert",
      data: segments
        .filter(status => status.rag === StatusIndicator.Red)
        .map(status => ({
          x: status.label,
          y: [status.start.getTime(), status.end.getTime()]
        }))
    }
  ];

  const numStatusBars = new Set(statuses.map(status => status.objectName)).size;
  const baseHeight = numStatusBars > 0 ? 90 : 0;
  const dynamicChartHeight = 28 * numStatusBars;

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="rangeBar"
        height={baseHeight + dynamicChartHeight}
      />
    </div>
  );
}

interface HeatmapSegment {
  label: string;
  start: Date;
  end: Date;
  rag: StatusIndicator;
}

function mergeStatuses(statuses: Status[]): HeatmapSegment[] {
  const now = new Date();

  const priorities = new Map<StatusIndicator, number>([
    [StatusIndicator.Green, 0],
    [StatusIndicator.Amber, 1],
    [StatusIndicator.Red, 2]
  ]);
  const groupedStatuses: Record<string, HeatmapSegment[]> = {};
  for (let s of statuses) {
    const key = s.objectName || "Unknown";
    (groupedStatuses[key] = groupedStatuses[key] || []).push({
      label: key,
      rag: s.status,
      start: s.timestampStart,
      end: s.timestampEnd ?? now
    });
  }

  for (let objectName of Object.keys(groupedStatuses)) {
    const group = groupedStatuses[objectName];
    const hours = new Map<number, StatusIndicator>();

    for (let segment of group) {
      for (let hour of lerpHours(segment.start, segment.end)) {
        const hourTicks = hour.getTime();
        const old = hours.get(hourTicks);
        if (
          old === undefined ||
          (priorities.get(segment.rag) ?? -1) > (priorities.get(old) ?? -1)
        ) {
          hours.set(hourTicks, segment.rag);
        }
      }
    }

    const timeseries = [...hours.keys()]
      .sort((a, b) => a - b)
      .map(h => ({
        label: objectName,
        rag: hours.get(h) ?? StatusIndicator.Unknown,
        start: new Date(h),
        end: new Date(h + 60 * 60 * 1000)
      }));

    const squashed = [timeseries[0]];
    for (let segment of timeseries.slice(1)) {
      const top = squashed[squashed.length - 1];
      if (
        top.end.getTime() === segment.start.getTime() &&
        segment.rag === top.rag
      ) {
        squashed[squashed.length - 1] = {
          ...top,
          end: segment.end
        };
      } else {
        squashed.push(segment);
      }
    }

    groupedStatuses[objectName] = squashed;
  }

  return Object.values(groupedStatuses).flat();
}

function* lerpHours(start: Date, end: Date): IterableIterator<Date> {
  if (start > end) return;
  start = new Date(start.getTime());
  start.setHours(start.getHours(), 0, 0, 0);
  end = new Date(end.getTime() - 60 * 60 * 1000);
  end.setHours(end.getHours(), 0, 0, 0);

  let current = start;
  do {
    yield current;
    current = new Date(current.getTime() + 60 * 60 * 1000);
  } while (current <= end);
}
