import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

var reactPlugin = new ReactPlugin();

if (process.env.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING) {
  var appInsights = new ApplicationInsights({
    config: {
      connectionString:
        process.env.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING,
      enableAutoRouteTracking: true,
      extensions: [reactPlugin],
      enableCorsCorrelation: true,
      correlationHeaderExcludedDomains: ["tableau.com"]
    }
  });

  appInsights.loadAppInsights();
}

export { reactPlugin };
