import { useContext, useEffect, useState } from "react";
import { Alert } from "../models/Alert";
import { AlertRepoContext } from "../contexts/AlertRepoContext";

interface AlertCounts {
  inverterCount: number;
  sensorCount: number;
  siteCount: number;
  dataCount: number;
}

export function useAlertTypeCounts(alerts: Alert[]): AlertCounts {
  const alertsRepo = useContext(AlertRepoContext);
  const [counts, setCounts] = useState<AlertCounts>({
    inverterCount: 0,
    sensorCount: 0,
    siteCount: 0,
    dataCount: 0
  });
  const alertHashes = alerts
    .map(a => a.alertHash)
    .sort()
    .join("|");

  useEffect(() => {
    (async () => {
      const typeLookup: Record<string, string> = {};
      const analyticIds = Array.from(new Set(alerts.map(a => a.analyticId)));

      for (let analyticId of analyticIds) {
        const config = await alertsRepo.getAnalytic(analyticId);
        if (config) {
          typeLookup[analyticId] = config.associatedAssetType;
        }
      }

      const inverterCount = alerts.filter(
        a =>
          !typeLookup[a.analyticId] || typeLookup[a.analyticId] === "inverter"
      ).length;
      const sensorCount = alerts.filter(
        a => typeLookup[a.analyticId] === "pyranometer"
      ).length;
      const siteCount = alerts.filter(a => typeLookup[a.analyticId] === "site")
        .length;
      const dataCount = alerts.filter(
        a =>
          a.analyticGroup === "data-quality" ||
          a.analyticGroup === "data_quality"
      ).length;

      setCounts({ inverterCount, sensorCount, siteCount, dataCount });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertHashes]);

  return counts;
}
