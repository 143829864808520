import { parseISO } from "date-fns";

export class Token {
  private readonly _token: string;
  private readonly _expiry: Date;

  constructor(token: string, expiry: Date) {
    this._token = token;
    this._expiry = expiry;
  }

  get token(): string {
    return this._token;
  }

  get expiry(): Date {
    return this._expiry;
  }
}

export function tokenFromResponse(response: {
  token: string;
  expiry: string;
}): Token {
  return new Token(response.token, parseISO(response.expiry));
}
