import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { CustomNavigationClient } from "./NavigationClient";
import { InteractionType } from "@azure/msal-browser";
import { Loading } from "./pages/Loading";
import { LoginError } from "./pages/LoginError";

export const MSALNavigationProvider: ({
  children
}: React.PropsWithChildren<unknown>) => JSX.Element = ({
  children
}: PropsWithChildren<unknown>): JSX.Element => {
  const navigationClient = useNavigate();
  const pca = useMsal();
  const customNavClient = new CustomNavigationClient(navigationClient);
  pca.instance.setNavigationClient(customNavClient);
  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        errorComponent={LoginError}
        loadingComponent={Loading}
      >
        {children}
      </MsalAuthenticationTemplate>
    </>
  );
};
