export const calculatePriorDate = (priorMonths: number) => {
  const date = new Date();
  date.setMonth(date.getMonth() - priorMonths);
  return date;
};

export const calculatePriorDateDays = (priorDays: number) => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() - priorDays);
  return date;
};
