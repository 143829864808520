import {
  PrimaryButton,
  Select,
  SelectOption
} from "@res/smart-ui-component-library";
import { useContext, useEffect, useState } from "react";
import { InsightRepoContext } from "../../contexts/InsightRepoContext";
import "./AddToInsightButton.scss";
import { InsightDto } from "../../repositories/InsightRepo";
import { useNavigate } from "react-router-dom";

export function AddToInsightButton({
  siteId,
  alertHash
}: {
  siteId: string;
  alertHash: string;
}) {
  const insightRepo = useContext(InsightRepoContext);
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [isSelecting, setIsSelecting] = useState(false);
  const [insights, setInsights] = useState<Record<string, InsightDto>>({});
  const navigate = useNavigate();

  useEffect(() => {
    const f = async () => {
      const insights = await insightRepo.listInsights([siteId]);
      const options = insights.map(i => ({ value: i.id, label: i.title }));
      setInsights(insights.reduce((acc, i) => ({ ...acc, [i.id]: i }), {}));
      setOptions(options);
    };
    f();
  }, [insightRepo, siteId]);

  const addToInsight = async (insightId: string) => {
    const insight = insights[insightId];
    const alertIds = [...insight.alertIds, alertHash];
    const updatedInsight = { ...insight, alertIds };

    await insightRepo.updateInsight(insightId, updatedInsight);
    navigate(`/insights/insight/${insightId}`);
  };

  return (
    <div>
      {isSelecting && (
        <Select
          className="add-to-insight-dropdown"
          menuIsOpen
          options={options}
          name="addToInsight"
          id="addToInsight"
          onChange={(option: SelectOption) =>
            addToInsight(option.value as string)
          }
          autoFocus
          onBlur={() => setIsSelecting(false)}
          onMenuClose={() => setIsSelecting(false)}
        />
      )}
      {!isSelecting && (
        <PrimaryButton useUnoStyling onClick={() => setIsSelecting(true)}>
          Add to Insight
        </PrimaryButton>
      )}
    </div>
  );
}
