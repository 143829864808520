import { Select, SelectOption } from "@res/smart-ui-component-library";
import { SiteShortConfig } from "../../models/SiteShortConfig";
import { SelectedPortfolioContext } from "../../contexts/SelectedPortfolioContext";
import { useContext, useEffect, useState } from "react";
import { usePortfolio } from "../../hook/usePortfolioSites";

interface SelectSiteProps {
  selectedSite: SiteShortConfig | undefined;
  setSelectedSite: (site?: SiteShortConfig) => void;
  hideLabel?: boolean;
}

const SelectSite = ({
  selectedSite,
  setSelectedSite,
  hideLabel
}: SelectSiteProps) => {
  const { selectedPortfolio } = useContext(SelectedPortfolioContext);
  const portfolio = usePortfolio(selectedPortfolio?.id);
  const [siteOptions, setSiteOptions] = useState<SiteShortConfig[]>([]);

  useEffect(() => {
    if (!portfolio) {
      setSiteOptions([]);
      return;
    }

    setSiteOptions(
      portfolio.sites.map(
        s => new SiteShortConfig(s.siteName, s.siteId, s.technology)
      )
    );
  }, [portfolio]);

  return (
    <Select
      label={hideLabel ? undefined : "Site"}
      aria-label={"Site"}
      value={
        selectedSite
          ? {
              value: selectedSite.siteId,
              label: selectedSite.siteName
            }
          : null
      }
      name="site"
      options={siteOptions.map(o => ({
        value: o.siteId,
        label: o.siteName
      }))}
      onChange={(e: SelectOption) => {
        setSelectedSite(siteOptions.find(s => s.siteId === e.value));
      }}
      className="dropdown"
      placeholder={
        selectedPortfolio?.id ? "Select Site..." : "Portfolio not selected..."
      }
    />
  );
};

export default SelectSite;
