interface SiteShortConfigInterface {
  siteName: string;
  siteId: string;
  technology: string;
}

interface ResponseInterface {
  sites: Array<SiteShortConfigInterface>;
}

export class SiteShortConfig {
  private _siteName: string;
  private _siteId: string;
  private _technology: string;
  constructor(siteName: string, siteId: string, technology: string) {
    this._siteName = siteName;
    this._siteId = siteId;
    this._technology = technology;
  }
  public get siteName(): string {
    return this._siteName;
  }
  public get siteId(): string {
    return this._siteId;
  }
  public get technology(): string {
    return this._technology;
  }
  public get isSolar(): boolean {
    return this._technology.toLowerCase() === "solar";
  }
  public get isWind(): boolean {
    return this._technology.toLowerCase().startsWith("wind");
  }
}

export function createSiteShortConfig(
  siteName: string,
  siteId: string,
  technology: string
) {
  return new SiteShortConfig(siteName, siteId, technology);
}

export function siteShortConfigListFromResponse(
  responseObject: ResponseInterface
) {
  return responseObject.sites.map(({ siteId, siteName, technology }) =>
    createSiteShortConfig(siteName, siteId, technology)
  );
}
