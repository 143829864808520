import { Paper } from "../../Paper";

import "./CounterHero.scss";

export function CounterHero({
  inverterCount,
  sensorCount,
  siteCount,
  dataCount
}: {
  inverterCount: number;
  sensorCount: number;
  siteCount: number;
  dataCount: number;
}) {
  return (
    <Paper>
      <div className="counter-container">
        <div className="counter-col">
          <h3>{Intl.NumberFormat().format(siteCount)}</h3>
          <h5>Site Alerts</h5>
        </div>

        <div className="counter-col">
          <h3>{Intl.NumberFormat().format(inverterCount)}</h3>
          <h5>Inverter Alerts</h5>
        </div>

        <div className="counter-col">
          <h3>0</h3>
          <h5>String Alerts</h5>
        </div>

        <div className="counter-col">
          <h3>{Intl.NumberFormat().format(sensorCount)}</h3>
          <h5>Sensor Alerts</h5>
        </div>

        <div className="counter-col">
          <h3>{Intl.NumberFormat().format(dataCount)}</h3>
          <h5>Data Alerts</h5>
        </div>
      </div>
    </Paper>
  );
}
