import React, { PropsWithChildren, useState } from "react";
import { PortfolioListItem } from "../models/Portfolio";

interface PortfolioContextType {
  selectedPortfolio: PortfolioListItem | undefined;
  setSelectedPortfolio: (p: PortfolioListItem | undefined) => void;
}

export const SelectedPortfolioContext = React.createContext<
  PortfolioContextType
>({
  selectedPortfolio: undefined,
  setSelectedPortfolio: () => {}
});

export const SelectedPortfolioProvider = ({
  children
}: PropsWithChildren<unknown>) => {
  const [selectedPortfolio, setSelectedPortfolio] = useState<
    PortfolioListItem | undefined
  >();

  return (
    <SelectedPortfolioContext.Provider
      value={{ selectedPortfolio, setSelectedPortfolio }}
    >
      {children}
    </SelectedPortfolioContext.Provider>
  );
};
