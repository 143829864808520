import { statusFromRAG, StatusIndicator } from "./StatusIndicator";
import { parseISO } from "date-fns";

export class Status {
  private readonly _statusHash: string;
  private readonly _siteId: string;
  private readonly _objectId: string;
  private readonly _objectName: string;
  private readonly _analyticGroup: string;
  private readonly _analyticId: string;
  private readonly _analyticName: string;
  private readonly _categoryId: string;
  private readonly _status: StatusIndicator;
  private readonly _value: number | null;
  private readonly _unit: string;
  private readonly _context: string;
  private readonly _isActive: boolean;
  private readonly _publishStatus: boolean;
  private readonly _shortDescription: string;
  private readonly _timestampStart: Date;
  private readonly _timestampEnd: Date | undefined;

  constructor(
    statusHash: string,
    siteId: string,
    objectId: string,
    objectName: string,
    analyticGroup: string,
    analyticId: string,
    analyticName: string,
    categoryId: string,
    status: StatusIndicator,
    value: number | null,
    unit: string,
    context: string,
    isActive: boolean,
    publishStatus: boolean,
    shortDescription: string,
    timestampStart: Date,
    timestampEnd?: Date | undefined
  ) {
    this._statusHash = statusHash;
    this._siteId = siteId;
    this._objectId = objectId;
    this._objectName = objectName;
    this._analyticGroup = analyticGroup;
    this._analyticId = analyticId;
    this._analyticName = analyticName;
    this._categoryId = categoryId;
    this._status = status;
    this._value = value;
    this._unit = unit;
    this._context = context;
    this._isActive = isActive;
    this._publishStatus = publishStatus;
    this._timestampStart = timestampStart;
    this._timestampEnd = timestampEnd;
    this._shortDescription = shortDescription;
  }

  get statusHash(): string {
    return this._statusHash;
  }

  get siteId(): string {
    return this._siteId;
  }

  get objectId(): string {
    return this._objectId;
  }

  get objectName(): string {
    return this._objectName;
  }

  get analyticGroup(): string {
    return this._analyticGroup;
  }

  get analyticId(): string {
    return this._analyticId;
  }

  get analyticName(): string {
    return this._analyticName;
  }

  get categoryId(): string {
    return this._categoryId;
  }

  get status(): StatusIndicator {
    return this._status;
  }

  get value(): number | null {
    return this._value;
  }

  get unit(): string {
    return this._unit;
  }

  get context(): string {
    return this._context;
  }

  get timestampStart(): Date {
    return this._timestampStart;
  }

  get timestampEnd(): Date | undefined {
    return this._timestampEnd;
  }

  get isActive(): boolean {
    return this._isActive;
  }

  get publishStatus(): boolean {
    return this._publishStatus;
  }

  get durationMilliseconds(): number {
    return (
      (this.timestampEnd ?? new Date()).getTime() -
      this.timestampStart.getTime()
    );
  }

  get shortDescription(): string {
    return this._shortDescription;
  }
}

export function statusFromResponse(response: {
  timestampEnd: null | string;
  statusHash: string;
  timestampModified: string;
  statusValueUnit: string;
  statusValue: number;
  analyticGroup: string;
  ragStatus: string;
  title: string;
  isOpen: boolean;
  publishStatus: boolean;
  analyticId: string;
  analyticName: string;
  timestampCreated: string;
  timestampStart: string;
  siteId: string;
  statusContext: string;
  objectId: string;
  objectName: string;
  categoryId: string;
  shortDescription: string;
}): Status {
  return new Status(
    response.statusHash,
    response.siteId,
    response.objectId,
    response.objectName,
    response.analyticGroup,
    response.analyticId,
    response.analyticName,
    response.categoryId,
    statusFromRAG(response.ragStatus),
    response.statusValue,
    response.statusValueUnit,
    response.statusContext,
    response.isOpen,
    response.publishStatus,
    response.shortDescription,
    parseISO(response.timestampStart),
    response.timestampEnd ? parseISO(response.timestampEnd) : undefined
  );
}
