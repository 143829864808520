import "./AlertActions.scss";
import { Alert, WorkflowStatus } from "../../../models/Alert";
import {
  Cell,
  LayoutGrid,
  PrimaryButton,
  Radio,
  TextArea,
  TextButton,
  Tooltip
} from "@res/smart-ui-component-library";
import { useContext, useEffect, useState } from "react";
import { AlertComment } from "../../../models/AlertComment";
import { AlertRepoContext } from "../../../contexts/AlertRepoContext";
import { ThemeContext } from "../../../contexts/ThemeContext";
import { useMsal } from "@azure/msal-react";
import { parseISO } from "date-fns";

function WorkflowSection(props: {
  onWorkflowStatusChange: (workflowStatus: WorkflowStatus) => void;
  workflowStatus: WorkflowStatus;
}) {
  const options: [WorkflowStatus, string, string][] = [
    [WorkflowStatus.unconfirmed, "Unconfirmed", "Has not yet been reviewed."],
    [
      WorkflowStatus.inReview,
      "In Review",
      "Alert has been acknowledged and the process of confirming a data anomaly has begun."
    ],
    [WorkflowStatus.Rejected, "Reject", "There is no data anomaly present."],
    [
      WorkflowStatus.confirmedActive,
      "Confirmed - Active",
      "Data anomaly has been confirmed and the process of diagnosis is ongoing."
    ],
    [
      WorkflowStatus.confirmedArchived,
      "Confirmed - Archive",
      "Data anomaly has been confirmed and the process of diagnosis is complete."
    ],
    [
      WorkflowStatus.onHold,
      "On Hold",
      "The review process has begun but work has been paused. To be revisited later."
    ],
    [WorkflowStatus.superseded, "Superseded", "The alert can be ignored."]
  ];

  return (
    <fieldset className={"workflow"}>
      <legend className={"workflow-item"}>Workflow</legend>
      {options.map(([workflowStatus, label, description]) => (
        <div key={workflowStatus}>
          <Tooltip content={description} delay={100} direction="right">
            <Radio
              key={workflowStatus}
              id={`state-${workflowStatus}`}
              name={"state"}
              value={workflowStatus}
              label={label}
              onChange={() => props.onWorkflowStatusChange(workflowStatus)}
              defaultChecked={props.workflowStatus === workflowStatus}
            />
          </Tooltip>
        </div>
      ))}
    </fieldset>
  );
}

export const AlertActions = ({ alert }: { alert: Alert }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [comments, setComments] = useState<AlertComment[]>([]);
  const [
    selectedWorkflowStatus,
    setSelectedWorkflowStatus
  ] = useState<WorkflowStatus | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newCommentText, setNewCommentText] = useState("");
  const alertRepo = useContext(AlertRepoContext);
  const theme = useContext(ThemeContext);
  const userId = useMsal().accounts[0]?.idTokenClaims?.oid;

  const isMyComment = (comment: AlertComment) => {
    return userId === comment.userId;
  };

  useEffect(() => {
    setIsLoading(true);

    (async () => {
      const comments = await alertRepo.getComments(
        alert.siteId,
        alert.alertHash
      );

      setComments(comments);

      setIsLoading(false);
    })();
  }, [alert, alertRepo]);

  return (
    <>
      {isLoading && !comments && <h3>Loading...</h3>}
      {(!isLoading || comments) && (
        <form
          onSubmit={async e => {
            e.preventDefault();
            setIsSubmitting(true);

            if (newCommentText) {
              const newComment = await alertRepo.postComment(
                alert.siteId,
                alert.alertHash,
                newCommentText
              );
              setComments([...comments, newComment]);
              setNewCommentText("");
            }

            if (selectedWorkflowStatus) {
              alert.workflowStatus = selectedWorkflowStatus;
              await alertRepo.updateAlert(
                alert.siteId,
                alert.alertHash,
                alert.workflowStatus
              );
            }

            setIsSubmitting(false);
          }}
        >
          <div className="insight-form-container">
            <LayoutGrid>
              <Cell wLarge={2} wMedium={2} wSmall={2}>
                <WorkflowSection
                  workflowStatus={alert.workflowStatus}
                  onWorkflowStatusChange={ws => setSelectedWorkflowStatus(ws)}
                />
              </Cell>
              <Cell wLarge={10} wMedium={10} wSmall={10}>
                <div className={`comment-history-container ${theme}`}>
                  {comments.map((c, index) => (
                    <div
                      role="group"
                      aria-label="Comment"
                      key={c.commentId}
                      className={`comment-history-item`}
                    >
                      <p aria-label="comment text" className="comment-text">
                        {c.content}
                      </p>
                      <div
                        aria-label="comment metadata"
                        className="comment-metadata"
                      >
                        <div>
                          {c.userName} -{" "}
                          {parseISO(c.timestampCreated).toLocaleDateString(
                            undefined,
                            {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit"
                            }
                          )}
                        </div>
                        <div>
                          {isMyComment(c) && (
                            <TextButton
                              role={"button"}
                              aria-label={"Delete comment"}
                              onClick={e => {
                                e.preventDefault();
                                alertRepo.deleteComment(
                                  alert.siteId,
                                  c.commentId
                                );
                                setComments(
                                  comments.filter((_, id) => id !== index)
                                );
                              }}
                            >
                              <svg
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="2"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                              </svg>
                            </TextButton>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <TextArea
                  name={"comment-text"}
                  label={"Notes"}
                  value={newCommentText}
                  onChange={e => setNewCommentText(e.target.value)}
                />
              </Cell>

              <Cell wLarge={12} wMedium={12} wSmall={12}>
                <hr />
              </Cell>
              <Cell wLarge={12} wMedium={12} wSmall={12}>
                <div className="action-buttons">
                  <PrimaryButton
                    disabled={isSubmitting}
                    icon="check-circle-solid"
                    useUnoStyling
                  >
                    {!isSubmitting && <>Save</>}
                    {isSubmitting && <>Saving</>}
                  </PrimaryButton>
                </div>
                <div></div>
              </Cell>
            </LayoutGrid>
          </div>
        </form>
      )}
    </>
  );
};
