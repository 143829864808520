import { IoCalendarOutline } from "react-icons/io5";
import { BiTimer } from "react-icons/bi";
import { Tooltip } from "@res/smart-ui-component-library";

export function OngoingIndicator({ isOngoing }: { isOngoing: boolean }) {
  return (
    <Tooltip
      content={isOngoing ? "Ongoing" : "Historical"}
      delay={0}
      direction="bottom"
    >
      {isOngoing ? (
        <BiTimer role={"img"} aria-label={"Ongoing"} size="27px" />
      ) : (
        <IoCalendarOutline role={"img"} aria-label={"Historical"} size="21px" />
      )}
    </Tooltip>
  );
}
