import { Select, SelectOption } from "@res/smart-ui-component-library";
import { SelectProps } from "@res/smart-ui-component-library/build/components/Form/Select/Select";

export function CheckboxFilterComponent({
  checkboxOptions,
  ...rest
}: { checkboxOptions: Array<SelectOption> } & Omit<
  SelectProps,
  "options" | "name"
>) {
  return (
    <Select
      name="filter"
      options={checkboxOptions}
      defaultValue={checkboxOptions}
      isMulti={true}
      {...rest}
    />
  );
}
