import { Icon } from "@res/smart-ui-component-library";
import "./GreenIndicator.scss";
import { Tooltip } from "@res/smart-ui-component-library";

export function GreenIndicator({ withTooltip }: { withTooltip: boolean }) {
  const icon = (
    <Icon
      aria-label="Ok"
      role="img"
      className="icon-green"
      icon="check-circle-solid"
    />
  );

  if (withTooltip) {
    return (
      <Tooltip content="Ok" delay={100} direction="bottom">
        {icon}
      </Tooltip>
    );
  }
  return icon;
}
