import { Select, SelectOption } from "@res/smart-ui-component-library";
import { InsightDeviceDto } from "../../../repositories/InsightRepo";
import { AssetConfig } from "../../../models/SiteConfig";

interface DeviceDropdownProps {
  devices: InsightDeviceDto[];
  setDevices: (devices: InsightDeviceDto[]) => void;
  siteDevices: AssetConfig[] | undefined;
}

const DeviceDropdown = ({
  devices,
  setDevices,
  siteDevices
}: DeviceDropdownProps) => {
  const deviceOptions =
    siteDevices?.map(siteDevice => ({
      value: siteDevice.objectId,
      label: siteDevice.name
    })) ?? [];

  const value = devices.map(({ deviceId }) => {
    const deviceName = siteDevices?.find(
      siteDevice => siteDevice.objectId === deviceId
    )?.name;
    return {
      value: deviceId,
      label: deviceName ?? deviceId
    };
  });

  const onChange = (newDevices: SelectOption[]) =>
    setDevices(
      newDevices.map(({ value }) => ({
        deviceId: value as string
      }))
    );

  return (
    <Select
      className="insight-input"
      label="Devices"
      name="devices"
      value={value}
      options={deviceOptions}
      onChange={onChange}
      isMulti={true}
    />
  );
};

export default DeviceDropdown;
