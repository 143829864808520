import { useEffect } from "react";

export const useScript = (src: string) => {
  useEffect(() => {
    if (src?.length > 0) {
      const scriptTag = document.createElement("script");

      scriptTag.src = src;
      scriptTag.async = false;
      scriptTag.type = "module";
      scriptTag.setAttribute("data-testid", "useScript");

      document.body.appendChild(scriptTag);
      return () => {
        document.body.removeChild(scriptTag);
      };
    }
  }, [src]);
};
