import React from "react";
import { Token } from "../models/Token";
import {
  InMemoryTokenRepository,
  TokenRepository
} from "../repositories/TokenRepo";

export const TokenRepoContext = React.createContext<TokenRepository>(
  new InMemoryTokenRepository(new Token("test", new Date()))
);
