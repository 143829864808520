import React from "react";
import { SelectedSiteProvider } from "./SelectedSiteContext";
import { AnalyticProvider } from "./AnalyticContext";
import { SelectedPortfolioProvider } from "./SelectedPortfolioContext";

const ContextWrapper = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <SelectedPortfolioProvider>
      <SelectedSiteProvider>
        <AnalyticProvider>{children}</AnalyticProvider>
      </SelectedSiteProvider>
    </SelectedPortfolioProvider>
  );
};

export default ContextWrapper;
