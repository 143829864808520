import { SolarTableObject } from "@res/smart-ui-component-library";
import { Alert, WorkflowStatusOrder } from "../../../../models/Alert";
import { SiteShortConfig } from "../../../../models/SiteShortConfig";
import { calculateDaysBetweenDates } from "./CalculateDaysBetweenDates";

export const mapAlertToSolarTableItems = (
  alert: Alert,
  siteConfig: SiteShortConfig,
  analyticGroupName: string
) => {
  const siteTechnology = siteConfig.isSolar ? "solar" : "wind";
  const siteName = siteConfig.siteName;

  const analyticGroup = {
    primaryField: analyticGroupName,
    additionalFields: [alert.publishStatus]
  } as SolarTableObject;

  const analyticDurationInDays = calculateDaysBetweenDates(
    alert.timestampStart,
    alert.timestampEnd
  );

  const isActive = alert.isActive ? 0 : 1;

  const workflowStatusOrder = WorkflowStatusOrder.indexOf(alert.workflowStatus);
  const workflowStatus = {
    primaryField: workflowStatusOrder,
    additionalFields: [alert.workflowStatus]
  } as SolarTableObject;

  const alertActions = {
    primaryField: alert.siteId,
    additionalFields: [alert.alertHash]
  } as SolarTableObject;

  return [
    siteTechnology,
    siteName,
    alert.objectName,
    alert.status,
    analyticGroup,
    alert.analyticName,
    alert.timestampStart,
    analyticDurationInDays,
    isActive,
    workflowStatus,
    alert.lostProductionMwh,
    alertActions
  ];
};

export const mapStringsToSelectOptions = (items: string[]) => {
  return items
    .filter(a => a.length > 0)
    .map(a => {
      return { value: a, label: a };
    });
};
