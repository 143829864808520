import { useContext } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, RawDraftContentState } from "draft-js";
import { ThemeContext } from "../../contexts/ThemeContext";
import "./RichTextEditor.scss";

type RteProps = {
  initialContentState: RawDraftContentState;
  onEditorStateChange?: (editorState: EditorState) => void;
  readOnly?: boolean;
  editorClassName?: string;
  wrapperClassName?: string;
  toolbarClassName?: string;
  key: string;
};

export default function RichTextEditor({
  initialContentState,
  onEditorStateChange,
  readOnly,
  editorClassName,
  wrapperClassName,
  toolbarClassName
}: RteProps) {
  const theme = useContext(ThemeContext);

  const uploadCallback = (file: File) => {
    var reader = new FileReader();
    return new Promise<{ data: { url: string } }>((resolve, reject) => {
      reader.addEventListener("load", () =>
        resolve({ data: { url: reader.result as string } })
      );
      reader.addEventListener("error", e => reject(e));
      reader.readAsDataURL(file);
    });
  };

  return (
    <Editor
      initialContentState={initialContentState}
      onEditorStateChange={s => {
        onEditorStateChange?.(s);
      }}
      editorClassName={`analysis-editor ${theme} ${editorClassName ?? ""}`}
      toolbarClassName={`analysis-editor-toolbar ${theme} ${toolbarClassName ??
        ""}`}
      wrapperClassName={`analyis-editor-wrapper ${theme} ${wrapperClassName ??
        ""}`}
      toolbar={{
        options: [
          "inline",
          "blockType",
          "fontSize",
          "fontFamily",
          "list",
          "textAlign",
          "colorPicker",
          "link",
          "emoji",
          "image",
          "remove",
          "history"
        ],
        image: {
          uploadEnabled: true,
          uploadCallback,
          previewImage: true
        }
      }}
      toolbarHidden={readOnly}
      readOnly={readOnly}
    />
  );
}
