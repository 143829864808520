import { PrimaryButton } from "@res/smart-ui-component-library";
import { useContext, useState } from "react";
import { InsightRepoContext } from "../../contexts/InsightRepoContext";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../layout/LoadingSpinner";

export function CreateInsightButton({
  siteId,
  alertHash
}: {
  siteId?: string;
  alertHash?: string;
}) {
  const [isCreatingInsight, setIsCreatingInsight] = useState(false);
  const insightRepository = useContext(InsightRepoContext);
  const navigate = useNavigate();

  return (
    <div>
      <PrimaryButton
        name="create-insight"
        onClick={async () => {
          setIsCreatingInsight(true);
          const insightId =
            alertHash && siteId
              ? (await insightRepository.createFromAlert(siteId, alertHash)).id
              : "new";
          navigate(`/insights/insight/${insightId}`);
          setIsCreatingInsight(false);
        }}
        disabled={isCreatingInsight}
        icon="plus"
        useUnoStyling
      >
        {!isCreatingInsight && <>Create Insight</>}
        {isCreatingInsight && <LoadingSpinner showText={false} />}
      </PrimaryButton>
    </div>
  );
}
