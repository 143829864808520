import { Configuration, RedirectRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID || "",
    authority: process.env.REACT_APP_AUTH_AUTHORITY || "",
    redirectUri: "/",
    postLogoutRedirectUri: "/"
  }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const alertsLoginRequest: RedirectRequest = {
  scopes: [process.env.REACT_APP_ALERTS_API_SCOPE || ""]
};
