import { PropsWithChildren } from "react";
import "./Layout.scss";
import { Helmet } from "react-helmet-async";
import { OurNavbar } from "./nav/OurNavbar";
import { LayoutGrid } from "@res/smart-ui-component-library";

export default function Layout({
  children,
  pageName
}: PropsWithChildren<{
  pageName: string;
}>) {
  return (
    <>
      <Helmet>
        <title>UNO Analytics - {pageName}</title>
        <body />
      </Helmet>
      <OurNavbar />
      <LayoutGrid className="page-end-margin">{children}</LayoutGrid>
    </>
  );
}
