import { Input, TextArea } from "@res/smart-ui-component-library";
import StatusDropdown from "../../../components/insight/dropdown/StatusDropdown";
import {
  InsightDeviceDto,
  InsightDto
} from "../../../repositories/InsightRepo";
import DeviceDropdown from "../../../components/insight/dropdown/DevicesDropdown";
import { AssetConfig } from "../../../models/SiteConfig";
import SelectSite from "../../../components/nav/SelectSite";
import { useContext } from "react";
import { SelectedSiteContext } from "../../../contexts/SelectedSiteContext";

interface SummarySectionProps {
  theme: "light" | "dark";
  workingInsight: InsightDto;
  setWorkingInsight: (insight: InsightDto) => void;
  siteDevices: AssetConfig[] | undefined;
  isNewInsight: boolean;
}

export default function SummarySection({
  theme,
  workingInsight,
  setWorkingInsight,
  siteDevices,
  isNewInsight
}: Readonly<SummarySectionProps>) {
  const [selectedSite, setSelectedSite] = useContext(SelectedSiteContext);
  return (
    <>
      {isNewInsight ? (
        <SelectSite
          selectedSite={selectedSite}
          setSelectedSite={site => {
            setWorkingInsight({
              ...workingInsight,
              siteId: site?.siteId ?? ""
            });
            setSelectedSite(site);
          }}
        />
      ) : (
        <>
          <Input
            className={`insight-input disabled-input ${theme}`}
            name="insightId"
            type="text"
            label="Insight ID"
            disabled
            value={workingInsight.id}
          />
          <Input
            className={`insight-input disabled-input ${theme}`}
            name="siteId"
            type="text"
            label="Asset ID"
            disabled
            value={workingInsight.siteId}
          />
        </>
      )}
      <Input
        className="insight-input"
        name="title"
        type="text"
        label="Title"
        value={workingInsight.title}
        onChange={e =>
          setWorkingInsight({ ...workingInsight, title: e.target.value })
        }
      />
      <TextArea
        className="insight-input"
        name="summary"
        label="Summary"
        rows={4}
        value={workingInsight.summary}
        onChange={e =>
          setWorkingInsight({ ...workingInsight, summary: e.target.value })
        }
      />
      <DeviceDropdown
        devices={workingInsight.devices}
        setDevices={(devices: InsightDeviceDto[]) => {
          setWorkingInsight({ ...workingInsight, devices });
        }}
        siteDevices={siteDevices}
      />
      <StatusDropdown
        status={workingInsight.status}
        setStatus={(status: string) => {
          setWorkingInsight({ ...workingInsight, status });
        }}
      />

      <div
        className="insight-input"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          alignItems: "end"
        }}
      >
        <Input
          label="Impact"
          type="text"
          name="impact"
          value={workingInsight.impact}
          onChange={e =>
            setWorkingInsight({
              ...workingInsight,
              impact: parseInt(e.target.value)
            })
          }
        />
        <Input
          type="text"
          name="impact-unit"
          value={workingInsight.impactUnit}
          onChange={e =>
            setWorkingInsight({ ...workingInsight, impactUnit: e.target.value })
          }
        />
      </div>
    </>
  );
}
