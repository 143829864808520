import React, { PropsWithChildren } from "react";
import { Helmet } from "react-helmet-async";
import { Paper } from "./Paper";

export function ErrorLayout({
  pageName,
  children
}: PropsWithChildren<{ pageName: string }>) {
  return (
    <>
      <Helmet>
        <title>UNO Analytics - {pageName}</title>
        <body className={"bg-light"} />
      </Helmet>
      <Paper>{children}</Paper>
    </>
  );
}
