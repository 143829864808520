export const calculateDaysBetweenDates = (
  timestampStart: Date,
  timestampEnd?: Date | undefined
) => {
  const start = timestampStart;
  const end = timestampEnd || new Date();
  const timeDiff = end.getTime() - start.getTime();
  const durationInDays = timeDiff / (1000 * 3600 * 24);
  return durationInDays;
};
