import { useEffect, useMemo, useState } from "react";
import RichTextEditor from "../rich_text_editor/RichTextEditor";
import "./NewInsightAnalysis.scss";
import { PrimaryButton } from "@res/smart-ui-component-library";
import { EditorState } from "react-draft-wysiwyg";
import { RawDraftContentState, convertToRaw } from "draft-js";
import { uniqueId } from "lodash";
import LoadingSpinner from "../layout/LoadingSpinner";

export function NewInsightAnalysis({
  submitAnalysis
}: {
  submitAnalysis: (content: string) => Promise<void>;
}) {
  const [key, setKey] = useState<string>("");
  const [isSaving, setIsSaving] = useState(false);
  const [editorState, setEditorState] = useState<EditorState>();
  const initialContentState = useMemo(
    () => ({ blocks: [], entityMap: {} } as RawDraftContentState),
    []
  );

  useEffect(() => setKey(uniqueId()), []);

  return (
    <>
      <RichTextEditor
        key={key}
        editorClassName="new-analysis-editor"
        initialContentState={initialContentState}
        onEditorStateChange={s => setEditorState(s)}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          marginTop: "1rem"
        }}
      >
        <PrimaryButton
          onClick={async () => {
            setIsSaving(true);
            const content = JSON.stringify(
              convertToRaw(editorState!.getCurrentContent())
            );
            await submitAnalysis(content);
            setIsSaving(false);
            setKey(uniqueId());
          }}
          icon="check-circle-solid"
          disabled={isSaving || !editorState}
          useUnoStyling
        >
          {!isSaving ? <>Save Analysis</> : <LoadingSpinner showText={false} />}
        </PrimaryButton>
      </div>
    </>
  );
}
