import { format } from "date-fns";

export const formatDateTimeForTableau = (dateTime: Date | undefined) => {
  if (dateTime === undefined) return "";
  return format(dateTime, "yyyy-MM-dd HH:mm:ss");
};

export const formatSensorValue = (sensorValue: string | undefined) => {
  const parts = sensorValue?.match(
    /HSrot|IMSgen|IMSrot|NDE|DE|HS|[A-Z][a-z]+(?=[0-9])|[A-Z][a-z]*|[0-9]+|[A-Z](?![a-z])/g
  );
  return parts ? parts.join(" ") : undefined;
};
