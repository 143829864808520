import {
  Alert,
  alertFromResponse,
  WorkflowStatus,
  alertFromExample
} from "../models/Alert";
import axios from "axios";
import { AlertComment } from "../models/AlertComment";
import { v4 as uuid4 } from "uuid";
import { ragFromStatus } from "../models/StatusIndicator";
import qs from "qs";
import { AlertResponse } from "../models/alerts/AlertResponse";
import {
  SiteShortConfig,
  siteShortConfigListFromResponse
} from "../models/SiteShortConfig";
import { SiteConfig } from "../models/SiteConfig";
import { AnalyticConfig } from "../models/AnalyticConfig";
import { Portfolio, PortfolioListItem } from "../models/Portfolio";

interface ListAlertsParams {
  siteIds?: string[];
  isOpen?: boolean;
}

export interface AlertRepository {
  listAlerts(query?: ListAlertsParams): Promise<Alert[]>;

  getComments(siteId: string, alertHash: string): Promise<AlertComment[]>;

  postComment(
    siteId: string,
    alertHash: string,
    content: string
  ): Promise<AlertComment>;

  postStatuses(alerts: Alert[]): Promise<void>;

  deleteComment(testSite: string, commentId: string): void;

  updateAlert(
    siteId: string,
    alertId: string,
    workFlowStatus: WorkflowStatus
  ): Promise<Alert>;

  getAlert(
    siteId: string | undefined,
    alertId: string | undefined
  ): Promise<Alert | undefined>;

  getSites(): Promise<Array<SiteShortConfig>>;

  getSite(siteId: string): Promise<SiteConfig | undefined>;

  getAnalytics(): Promise<AnalyticConfig[]>;

  getAnalytic(analyticId: string): Promise<AnalyticConfig | undefined>;

  getPortfolios(): Promise<Array<PortfolioListItem>>;

  getPortfolio(portfolioId: string): Promise<Portfolio | undefined>;
}

export class InMemoryAlertRepository implements AlertRepository {
  private readonly alerts: Record<string, Record<string, Alert>>;
  private readonly comments: Record<string, AlertComment[]>;
  private readonly userPlaceholder: string;
  private readonly utcNow: () => string;
  private readonly siteShortConfigs: SiteShortConfig[] = [];
  private readonly sites: Record<string, SiteConfig> = {};
  private readonly analyticIds: string[] = [];
  private readonly analytics: Record<string, AnalyticConfig> = {};
  private readonly portfolios: Record<string, Portfolio> = {};

  constructor(
    alerts: Record<string, Alert[]>,
    comments?: Record<string, AlertComment[]>,
    userPlaceholder?: string,
    utcNow?: () => string,
    siteShortConfigs?: Array<SiteShortConfig>,
    sites?: Record<string, SiteConfig>,
    analyticIds?: string[],
    analytics?: Record<string, AnalyticConfig>,
    portfolios?: Record<string, Portfolio>
  ) {
    if (siteShortConfigs) {
      this.siteShortConfigs = siteShortConfigs;
    }
    if (sites) {
      this.sites = sites;
    }
    if (analyticIds) {
      this.analyticIds = analyticIds;
    }
    if (analytics) {
      this.analytics = analytics;
    }
    if (portfolios) {
      this.portfolios = portfolios;
    }

    this.userPlaceholder = userPlaceholder ?? "";
    this.utcNow = utcNow ?? (() => new Date().toISOString());

    let morphedAlerts: Record<string, Record<string, Alert>> = {};

    for (const siteId in alerts) {
      let siteAlerts = alerts[siteId];
      morphedAlerts[siteId] = {};

      for (let alertHash in siteAlerts) {
        let siteAlert = siteAlerts[alertHash];
        morphedAlerts[siteId][siteAlert.alertHash || ""] = siteAlert;
      }
    }

    this.alerts = morphedAlerts;

    this.comments = comments ? JSON.parse(JSON.stringify(comments)) : {};
  }

  deleteComment(testSite: string, commentId: string): Promise<void> {
    if (!(testSite in this.comments)) {
      return Promise.resolve();
    }
    const foundCommentId = this.comments[testSite].findIndex(
      e => e.commentId === commentId
    );

    if (foundCommentId === null) {
      return Promise.resolve();
    }

    delete this.comments[testSite][foundCommentId];

    return Promise.resolve();
  }

  async getComments(
    siteId: string,
    alertHash: string
  ): Promise<AlertComment[]> {
    return this.comments[alertHash] ?? [];
  }

  async postComment(
    siteId: string,
    alertHash: string,
    content: string
  ): Promise<AlertComment> {
    const comment: AlertComment = {
      commentId: uuid4(),
      content,
      timestampCreated: this.utcNow(),
      userId: this.userPlaceholder,
      userName: this.userPlaceholder
    };

    const comments = this.comments[alertHash] ?? [];
    this.comments[alertHash] = comments
      .filter(c => c.commentId !== comment.commentId)
      .concat(comment);

    return comment;
  }

  listAlerts(query: ListAlertsParams = {}): Promise<Alert[]> {
    const { siteIds, isOpen } = query;

    let alerts = Object.entries(this.alerts)
      .filter(([key, _]) => siteIds === undefined || siteIds.includes(key))
      .reduce(
        (previous, [_, value]) => previous.concat(Object.values(value)),
        new Array<Alert>()
      );

    if (isOpen !== undefined) {
      alerts = alerts.filter(value => value.isActive === isOpen);
    }
    return Promise.resolve(alerts);
  }

  postAlert(alert: Alert): void {
    if (!this.alerts[alert.siteId]) {
      this.alerts[alert.siteId] = {};
    }

    this.alerts[alert.siteId][alert.alertHash] = alert;
  }

  postStatuses(alerts: Alert[]): Promise<void> {
    alerts.forEach(alert => this.postAlert(alert));

    return Promise.resolve();
  }

  updateAlert(
    siteId: string,
    alertId: string,
    workFlowStatus: WorkflowStatus
  ): Promise<Alert> {
    const alert = this.alerts[siteId][alertId];
    alert.workflowStatus = workFlowStatus;

    return Promise.resolve(Object.assign(alertFromExample({}), alert));
  }

  getAlert(
    siteId: string | undefined,
    alertId: string | undefined
  ): Promise<Alert | undefined> {
    if (!this.alerts[siteId || ""]) {
      return Promise.resolve(undefined);
    }

    const alert = this.alerts[siteId || ""][alertId || ""];

    return Promise.resolve(alert);
  }

  getSites(): Promise<Array<SiteShortConfig>> {
    return Promise.resolve(this.siteShortConfigs);
  }

  getSite(siteId: string): Promise<SiteConfig | undefined> {
    return Promise.resolve(this.sites[siteId]);
  }

  getAnalytics(): Promise<AnalyticConfig[]> {
    return Promise.resolve(Object.values(this.analytics));
  }

  getAnalytic(analyticId: string): Promise<AnalyticConfig | undefined> {
    return Promise.resolve(this.analytics[analyticId]);
  }

  getPortfolios(): Promise<PortfolioListItem[]> {
    const portfolios = Object.values(this.portfolios).map(p => ({
      id: p.id,
      name: p.name
    }));
    return Promise.resolve(portfolios);
  }

  getPortfolio(portfolioId: string): Promise<Portfolio | undefined> {
    return Promise.resolve(this.portfolios[portfolioId]);
  }
}

export class HTTPAlertRepository implements AlertRepository {
  private readonly baseUrl: string;
  private readonly getAuthToken: () => Promise<string | undefined>;

  constructor(
    baseUrl: string,
    getAuthToken: () => Promise<string | undefined>
  ) {
    this.baseUrl = baseUrl;
    this.getAuthToken = getAuthToken;
  }

  async getComments(
    siteId: string,
    alertHash: string
  ): Promise<AlertComment[]> {
    const response = await axios.get(
      `${this.baseUrl}/api/comments/${encodeURIComponent(
        siteId
      )}/${encodeURIComponent(alertHash)}`,
      {
        headers: await this.getAuthHeaders()
      }
    );
    const comments = response.data.comments;
    return comments;
  }

  async postComment(
    siteId: string,
    alertHash: string,
    content: string
  ): Promise<AlertComment> {
    const response = await axios.post(
      `${this.baseUrl}/api/comments/${encodeURIComponent(
        siteId
      )}/${encodeURIComponent(alertHash)}`,
      { content },
      {
        headers: await this.getAuthHeaders()
      }
    );
    return response.data;
  }

  async listAlerts(query: ListAlertsParams = {}): Promise<Alert[]> {
    let url = `${this.baseUrl}/api/alerts/`;
    const fetchResponse = await axios.get(url, {
      headers: await this.getAuthHeaders(),
      params: { ...query },
      paramsSerializer: function(params) {
        return qs.stringify(params, { indices: false });
      }
    });
    const json = await fetchResponse.data;

    return json.alerts.map(alertFromResponse);
  }

  async postStatuses(alerts: Alert[]): Promise<void> {
    let url = `${this.baseUrl}/api/status`;

    const toResponse = (alert: Alert): AlertResponse => ({
      workFlowStatus: alert.workflowStatus,
      alertHash: alert.alertHash,
      alertContext: alert.context,
      analyticId: alert.analyticId,
      categoryId: alert.categoryId,
      isOpen: !!alert.timestampEnd,
      objectId: alert.objectId,
      ragStatus: ragFromStatus(alert.status),
      siteId: alert.siteId,
      timestampEnd: alert.timestampEnd?.toISOString() ?? null,
      timestampStart: alert.timestampStart.toISOString(),
      title: "Manually Created Alert",
      timestampCreated: new Date().toISOString(),
      timestampModified: new Date().toISOString()
    });

    const body: AlertResponse[] = alerts.map(toResponse);

    await axios.post(
      url,
      { statuses: body },
      {
        headers: await this.getAuthHeaders()
      }
    );
  }

  async updateAlert(
    siteId: string,
    alertId: string,
    workFlowStatus: WorkflowStatus
  ): Promise<Alert> {
    const url = `${this.baseUrl}/api/alert/${siteId}/${alertId}`;
    const response = await axios.put(
      url,
      { workFlowStatus },
      { headers: await this.getAuthHeaders() }
    );
    return alertFromResponse(response.data);
  }

  private async getAuthHeaders() {
    return { Authorization: `Bearer ${await this.getAuthToken()}` };
  }

  async deleteComment(testSite: string, commentId: string): Promise<void> {
    await axios.delete(
      `${this.baseUrl}/api/comments/${encodeURIComponent(
        testSite
      )}/${encodeURIComponent(commentId)}`,
      {
        headers: await this.getAuthHeaders()
      }
    );
  }

  async getAlert(siteId: string, alertId: string): Promise<Alert | undefined> {
    const url = `${this.baseUrl}/api/alert/${encodeURIComponent(
      siteId
    )}/${encodeURIComponent(alertId)}`;
    try {
      const response = await axios.get(url, {
        headers: await this.getAuthHeaders()
      });
      return alertFromResponse(response.data);
    } catch {
      return Promise.resolve(undefined);
    }
  }

  async getSites(): Promise<Array<SiteShortConfig>> {
    let url = `${this.baseUrl}/api/config/sites`;
    const fetchResponse = await axios.get(url, {
      headers: await this.getAuthHeaders()
    });
    const json = await fetchResponse.data;
    return siteShortConfigListFromResponse(json);
  }

  async getSite(siteId: string): Promise<SiteConfig | undefined> {
    let url = `${this.baseUrl}/api/config/site/${siteId}`;
    try {
      const fetchResponse = await axios.get(url, {
        headers: await this.getAuthHeaders()
      });
      return await fetchResponse.data;
    } catch (err) {
      if ((err as any)?.response?.status === 404) {
        return undefined;
      }
      throw err;
    }
  }

  async getAnalytics(): Promise<AnalyticConfig[]> {
    let url = `${this.baseUrl}/api/config/analytics`;
    const fetchResponse = await axios.get(url, {
      headers: await this.getAuthHeaders()
    });
    const json = await fetchResponse.data;
    return json.analytics;
  }

  async getAnalytic(analyticId: string): Promise<AnalyticConfig | undefined> {
    let url = `${this.baseUrl}/api/config/analytic/${analyticId}`;
    try {
      const fetchResponse = await axios.get(url, {
        headers: await this.getAuthHeaders()
      });
      return await fetchResponse.data;
    } catch (err) {
      if ((err as any)?.response?.status === 404) {
        return undefined;
      }
      throw err;
    }
  }

  async getPortfolios(): Promise<PortfolioListItem[]> {
    const url = `${this.baseUrl}/api/config/portfolios`;
    const fetchResponse = await axios.get(url, {
      headers: await this.getAuthHeaders()
    });
    const json = await fetchResponse.data;
    return json.portfolios;
  }

  async getPortfolio(portfolioId: string): Promise<Portfolio | undefined> {
    const url = `${this.baseUrl}/api/config/portfolio/${portfolioId}`;
    try {
      const fetchResponse = await axios.get(url, {
        headers: await this.getAuthHeaders()
      });
      return await fetchResponse.data;
    } catch (err) {
      if ((err as any)?.response?.status === 404) {
        return undefined;
      }
      throw err;
    }
  }
}
