import { Oval } from "react-loader-spinner";

const LoadingSpinner = ({
  showText,
  className
}: {
  showText?: boolean;
  className?: string;
}) => {
  showText = showText ?? true;
  return (
    <div style={{ textAlign: "center" }} className={className}>
      <Oval
        height={13}
        width={13}
        color="#066578"
        wrapperStyle={{ display: "inline-block", marginRight: "0.5rem" }}
        wrapperClass=""
        visible={true}
        ariaLabel="Loading Data"
        secondaryColor="#077086"
        strokeWidth={5}
        strokeWidthSecondary={5}
      />
      {showText && (
        <h3 style={{ margin: "0", display: "inline-block" }}>Loading...</h3>
      )}
    </div>
  );
};

export default LoadingSpinner;
