import { useContext, useState } from "react";
import {
  FilterStatus,
  SelectOption,
  SolarTable,
  SolarTableColumn,
  SolarTableItem,
  SolarTableObject,
  Tooltip
} from "@res/smart-ui-component-library";

import { Paper } from "../../Paper";
import {
  Alert,
  WorkflowStatus,
  WorkflowStatusOrder
} from "../../../models/Alert";

import "./AlertTable.scss";
import { CheckboxFilterComponent } from "../filters/CheckboxFilterComponent";
import { ShareButton } from "./../../share_button/ShareButton";
import { TechnologyIcon } from "../../technology/TechnologyIcon";
import { SelectedSiteContext } from "../../../contexts/SelectedSiteContext";
import {
  mapStatusFromNumberToEnum,
  mapStatusToLabel
} from "../../../models/StatusIndicator";
import { Indicator } from "../../indicators/Indicator";
import { AnalyticContext } from "../../../contexts/AnalyticContext";
import ExclamationTriangle from "../../indicators/ExclamationTriangle";
import { OngoingIndicator } from "../../indicators/ongoing/OngoingIndicator";
import { AlertAction } from "../action-button/ActionButton";
import uniq from "lodash/uniq";
import "react-datepicker/dist/react-datepicker.css";
import { formatDays } from "./utils/FormatDays";
import {
  mapAlertToSolarTableItems,
  mapStringsToSelectOptions
} from "./utils/MapAlertToSolarTableItems";
import isEqual from "lodash/isEqual";
import { usePortfolio } from "../../../hook/usePortfolioSites";
import { SelectedPortfolioContext } from "../../../contexts/SelectedPortfolioContext";
import {
  getAlertsAfterFilter,
  getFilteredSiteNames,
  getFilteredTechnologies,
  getSitesAfterFilter
} from "./utils/FilterTableFilters";
import { CreateInsightButton } from "../../create_insight_button/CreateInsightButton";
import { AddToInsightButton } from "../../add_to_insight_button/AddToInsightButton";

const ONGOING_ALERT = {
  value: "True",
  label: "Ongoing"
};

const HISTORICAL_ALERT = {
  value: "False",
  label: "Historical"
};

interface FilterOptions {
  assetOptions: SelectOption[];
  deviceOptions: SelectOption[];
  severityOptions: SelectOption[];
  analyticOptions: SelectOption[];
  analyticGroupOptions: SelectOption[];
}

export function AlertTable({
  allAlerts,
  isOpen,
  setIsOpen,
  isAlertActions = true,
  areAlertsLoading,
  portfolioSelected
}: {
  allAlerts?: Alert[];
  isOpen: boolean | undefined;
  setIsOpen: (isOpen: boolean | undefined) => void;
  isAlertActions?: boolean;
  areAlertsLoading?: boolean;
  portfolioSelected?: boolean;
}) {
  const getSiteById = useContext(SelectedSiteContext)[2];
  const [ongoingFilterKey, setOngoingFilterKey] = useState(0);
  const { analytics: analyticConfigs } = useContext(AnalyticContext);
  const { selectedPortfolio } = useContext(SelectedPortfolioContext);
  const portfolio = usePortfolio(selectedPortfolio?.id);

  const alerts = isAlertActions ? allAlerts?.slice(0, 1) : allAlerts;
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    assetOptions: [],
    deviceOptions: [],
    severityOptions: [],
    analyticOptions: [],
    analyticGroupOptions: []
  });

  const clearOngoingFilter = () => {
    setIsOpen(true);
    setOngoingFilterKey(key => key + 1);
  };

  const getAnalyticGroupName = (analyticId: string) => {
    const relevantAnalyticConfig = analyticConfigs.filter(
      a => a.analyticId === analyticId
    );

    if (relevantAnalyticConfig.length === 0) return "";
    return relevantAnalyticConfig[0].analyticGroupName;
  };

  const technologyOptions = [
    { value: "solar", label: "Solar" },
    { value: "wind", label: "Wind" }
  ];

  const workflowStatusOptions = WorkflowStatusOrder.map((status, idx) => {
    return {
      value: idx,
      label: status
    };
  });

  const defaultSelectedWorkflows = workflowStatusOptions.slice(0, 3);

  const solarTableColumns: SolarTableColumn[] = [
    {
      name: "technology",
      title: "Technology",
      width: 100,
      renderItem: (item: SolarTableItem) => {
        const technology = item as string;
        return (
          <span className="technology-icon">
            <TechnologyIcon technology={technology} />
          </span>
        );
      },
      filterBy: "dropdown",
      filterByDropdownLogic: "includeAny",
      filterByOptions: technologyOptions
    },
    {
      name: "asset",
      title: "Asset",
      width: 130,
      renderItem: (item: SolarTableItem) => {
        const siteName = item as string;
        return <>{siteName}</>;
      },
      sortBy: "string",
      filterBy: "dropdown",
      filterByDropdownLogic: "includeAny",
      filterByOptions: filterOptions.assetOptions
    },
    {
      name: "device",
      title: "Device",
      width: 90,
      sortBy: "string",
      filterBy: "dropdown",
      filterByDropdownLogic: "includeAny",
      filterByOptions: filterOptions.deviceOptions
    },
    {
      name: "severity",
      title: "Severity",
      width: 70,
      renderItem: (item: SolarTableItem) => {
        const status = mapStatusFromNumberToEnum(item as number);
        return <Indicator status={status} withTooltip={true}></Indicator>;
      },
      filterBy: "dropdown",
      filterByDropdownLogic: "includeAny",
      filterByOptions: filterOptions.severityOptions,
      sortBy: "number"
    },
    {
      name: "group",
      title: "Group",
      width: 100,
      renderItem: (item: SolarTableItem) => {
        const { primaryField, additionalFields } = item as SolarTableObject;
        const group = primaryField as string;
        const publishStatus = additionalFields[0] as boolean;
        return (
          <>
            {!publishStatus && (
              <Tooltip
                content={"Preview Analytic. Values subject to change."}
                delay={0}
                direction="bottom"
              >
                <ExclamationTriangle
                  aria-label={"Preview Analytic"}
                  role={"img"}
                  width={"1.5em"}
                  height={"1.5em"}
                  style={{ verticalAlign: "middle", marginRight: "3px" }}
                  fill={"currentColor"}
                />
              </Tooltip>
            )}
            {group}
          </>
        );
      },
      sortBy: "string",
      filterBy: "dropdown",
      filterByDropdownLogic: "includeAny",
      filterByOptions: filterOptions.analyticGroupOptions
    },
    {
      name: "analytic",
      title: "Analytic",
      width: 100,
      sortBy: "string",
      filterBy: "dropdown",
      filterByDropdownLogic: "includeAny",
      filterByOptions: filterOptions.analyticOptions
    },
    {
      name: "startTime",
      title: "Start Time",
      renderItem: (item: SolarTableItem) => {
        const timestampStart = item as Date;
        return (
          <>
            {timestampStart.toLocaleDateString(undefined, {
              day: "2-digit",
              month: "short",
              year: "numeric"
            })}
          </>
        );
      },
      sortBy: "date",
      filterBy: "date",
      filterHelpText: "Filter by date",
      width: 100
    },
    {
      name: "duration",
      title: "Duration",
      renderItem: (item: SolarTableItem) => {
        const days = item as number;
        const formattedDays = formatDays(days);
        return <>{formattedDays}</>;
      },
      filterBy: "number",
      filterHelpText: "Filter by days",
      sortBy: "number",
      width: 100
    },
    {
      name: "ongoing",
      title: "Ongoing",
      width: 95,
      customFilter: () => {
        return (
          <>
            {isAlertActions ? (
              "Ongoing"
            ) : (
              <CheckboxFilterComponent
                key={ongoingFilterKey}
                onChange={(
                  selectedValues: { value: string; label: string }[]
                ) => {
                  let isOpen = undefined;
                  // when neither options are selected, or both are selected
                  // isOpen is to be set to undefined
                  if (selectedValues.length === 1) {
                    const selectedValue = selectedValues[0];
                    if (selectedValue.label === ONGOING_ALERT.label) {
                      isOpen = true;
                    } else if (selectedValue.label === HISTORICAL_ALERT.label) {
                      isOpen = false;
                    }
                  }

                  setIsOpen(isOpen);
                }}
                checkboxOptions={[ONGOING_ALERT, HISTORICAL_ALERT]}
                defaultValue={isOpen ? ONGOING_ALERT : HISTORICAL_ALERT}
                aria-label="ongoing-filter"
              />
            )}
          </>
        );
      },
      clearCustomFilter: clearOngoingFilter,
      renderItem: (item: SolarTableItem) => {
        const isActive = item === 0 ? true : false;
        return <OngoingIndicator isOngoing={isActive} />;
      },
      sortBy: "number"
    },
    {
      name: "workflow",
      title: "Workflow",
      width: 110,
      renderItem: (item: SolarTableItem) => {
        const { additionalFields } = item as SolarTableObject;
        const workflowStatus = additionalFields[0] as WorkflowStatus;
        return <>{workflowStatus}</>;
      },
      sortBy: "number",
      filterBy: "dropdown",
      filterByDropdownLogic: "includeAny",
      filterByDefaultOptions: defaultSelectedWorkflows,
      filterByOptions: workflowStatusOptions
    },
    {
      name: "lostProduction",
      title: "Lost Production (MWh)",
      width: 90,
      renderItem: (item: SolarTableItem) => {
        const lostProductionMwh = item as number;
        return <>{new Intl.NumberFormat().format(lostProductionMwh)}</>;
      },
      sortBy: "number",
      filterBy: "number"
    },
    {
      name: "clearAllFiltersColumn",
      title: "Actions",
      width: 50,
      showColumn: !isAlertActions,
      renderItem: (item: SolarTableItem) => {
        const { primaryField, additionalFields } = item as SolarTableObject;
        const alertSiteId = primaryField as string;
        const alertHash = additionalFields[0] as string;
        const newUrl = `/alerts/site/${encodeURIComponent(
          alertSiteId
        )}/alert/${encodeURIComponent(alertHash)}`;
        return (
          <a
            href={newUrl}
            target="_blank"
            rel="noreferrer"
            style={{ display: "block" }}
          >
            <AlertAction onClick={() => {}} />
          </a>
        );
      }
    }
  ];

  const solarTableItems: SolarTableItem[][] =
    alerts && alerts.every(a => getSiteById(a.siteId))
      ? alerts.map(alert =>
          mapAlertToSolarTableItems(
            alert,
            getSiteById(alert.siteId),
            getAnalyticGroupName(alert.analyticId)
          )
        )
      : [];

  const setColumnFiltersAfterFilter = (
    columnFilterStatus: FilterStatus[],
    items: SolarTableItem[][]
  ) => {
    if (isAlertActions) return;

    // get technologies and site names from applied filters (if any!)
    const filteredTechnologies = getFilteredTechnologies(columnFilterStatus);
    const filteredSiteNames = getFilteredSiteNames(columnFilterStatus);

    // get sites from portfolio based on applied technology filters
    const uniqueSiteNames = getSitesAfterFilter(
      portfolio,
      filteredTechnologies
    );

    // get alerts based on applied technology and site filters
    const filteredAlerts = getAlertsAfterFilter(
      alerts,
      filteredTechnologies,
      filteredSiteNames,
      getSiteById
    );

    // get unique options for device, severity, group and analytic filters
    // based on the filtered alerts i.e. alerts displayed in the table
    const uniqueObjectNames = uniq(
      filteredAlerts?.map(alert => alert.objectName)
    );

    const uniqueSeverityOptions = uniq(
      filteredAlerts?.map(alert => alert.status)
    )
      .map(severity => mapStatusFromNumberToEnum(severity))
      .map(severity => {
        return {
          value: severity,
          label: mapStatusToLabel(severity)
        } as SelectOption;
      });

    const uniqueAnalyticNames = uniq(
      filteredAlerts?.map(alert => alert.analyticName)
    );

    const uniqueAnalyticGroupNames = uniq(
      filteredAlerts?.map(alert => getAnalyticGroupName(alert.analyticId))
    );

    const newFilterOptions = {
      assetOptions: mapStringsToSelectOptions(uniqueSiteNames),
      deviceOptions: mapStringsToSelectOptions(uniqueObjectNames),
      severityOptions: uniqueSeverityOptions,
      analyticOptions: mapStringsToSelectOptions(uniqueAnalyticNames),
      analyticGroupOptions: mapStringsToSelectOptions(uniqueAnalyticGroupNames)
    };

    if (isEqual(filterOptions, newFilterOptions)) return;
    setFilterOptions(newFilterOptions);
  };

  return (
    <Paper hideBottomPadding hideTopPadding>
      <div className="table-key-and-share-button-container">
        {isAlertActions && (
          <>
            {!!alerts?.length && (
              <>
                <AddToInsightButton
                  siteId={alerts[0].siteId}
                  alertHash={alerts[0].alertHash}
                />
                <CreateInsightButton
                  siteId={alerts[0].siteId}
                  alertHash={alerts[0].alertHash}
                />
              </>
            )}
            <ShareButton />
          </>
        )}
      </div>

      <SolarTable
        data-testid="solar-table"
        columns={solarTableColumns.map(col =>
          isAlertActions
            ? { ...col, filterBy: undefined, sortBy: undefined }
            : col
        )}
        items={solarTableItems}
        enableSiteTimeZone={false}
        siteTimeZone={""}
        disablePagination={true}
        siteSelected={portfolioSelected}
        isLoading={areAlertsLoading}
        noDataText="No alerts found"
        setColumnFiltersAfterFilter={setColumnFiltersAfterFilter}
      />
    </Paper>
  );
}
