import { useEffect, useRef, useCallback, useState } from "react";
import { useScript } from "../hook/useScript";
import { Token } from "../models/Token";
import "./TableauContainer.scss";
import { retrieveTableauErrorCode } from "./tableau/RetrieveTableauErrorCode";
import { mapTableauErrorCode } from "./tableau/MapTableauErrorCode";
import useEmailAddress from "../hook/useEmailAddress";

interface VizWorkbook {
  changeParameterValueAsync: (
    paramName: string,
    value: string
  ) => Promise<boolean>;
}

interface VizRef extends HTMLDivElement {
  workbook: VizWorkbook;
}

type TableauParam = {
  paramName: string;
  value: string | undefined;
};

export const TableauContainer = ({
  name,
  params,
  token
}: {
  name: string;
  params: TableauParam[];
  token: Token | undefined;
}) => {
  useScript(
    "https://prod-useast-a.online.tableau.com/javascripts/api/tableau.embedding.3.6.0.js"
  );

  const vizRef = useRef<VizRef>(null);
  const [tableauErrorCode, setTableauErrorCode] = useState<number>();
  const userEmailAddress = useEmailAddress();

  const handleVizLoadError = useCallback((event: Event) => {
    const errorCode = retrieveTableauErrorCode(event);
    if (errorCode) {
      setTableauErrorCode(errorCode);
    }
  }, []);

  useEffect(() => {
    if (vizRef.current) {
      const vizEl = vizRef.current;
      vizEl.addEventListener("vizloaderror", handleVizLoadError);
    }
  }, [vizRef, handleVizLoadError]);

  const hasTokenExpired =
    token?.expiry !== undefined && token.expiry < new Date();
  const actualToken = hasTokenExpired ? null : token?.token;

  const tableauErrorMessage = tableauErrorCode
    ? mapTableauErrorCode(tableauErrorCode, userEmailAddress)
    : "";

  return (
    <div style={{ position: "relative" }}>
      {tableauErrorCode && (
        <p className="tableauErrorMessage">{tableauErrorMessage}</p>
      )}
      <tableau-viz
        ref={vizRef}
        id="tableauViz"
        data-testid="tableau-viz"
        src={`https://prod-useast-a.online.tableau.com/t/restechnical/views/${name}`}
        style={{
          marginTop: "1rem",
          height: window.innerHeight,
          width: window.innerWidth
        }}
        token={actualToken}
      >
        {params.map(({ paramName, value }) => (
          <viz-parameter key={paramName} name={paramName} value={value} />
        ))}
      </tableau-viz>
    </div>
  );
};
