import { statusFromRAG, StatusIndicator } from "./StatusIndicator";
import { parseISO } from "date-fns";

export enum WorkflowStatus {
  inReview = "InReview",
  Rejected = "Rejected",
  confirmedArchived = "ConfirmedArchived",
  unconfirmed = "Unconfirmed",
  confirmedActive = "ConfirmedActive",
  onHold = "OnHold",
  superseded = "Superseded"
}

export const WorkflowStatusOrder = [
  WorkflowStatus.unconfirmed,
  WorkflowStatus.inReview,
  WorkflowStatus.confirmedActive,
  WorkflowStatus.confirmedArchived,
  WorkflowStatus.Rejected,
  WorkflowStatus.onHold,
  WorkflowStatus.superseded
];

export class Alert {
  private _technology: string;
  private _alertHash: string;
  private readonly _siteId: string;
  private readonly _objectId: string;
  private readonly _objectName: string;
  private readonly _analyticGroup: string;
  private readonly _analyticId: string;
  private readonly _categoryId: string;
  private readonly _status: StatusIndicator;
  private readonly _context: string;
  private readonly _isActive: boolean;
  private readonly _timestampStart: Date;
  private readonly _timestampEnd: Date | undefined;
  private readonly _publishStatus: boolean;
  private readonly _lostProductionMwh: number;
  private readonly _dashboardUrl: string;
  private readonly _analyticName: string;
  private readonly _description: string;
  private readonly _title: string;

  constructor(
    technology: string,
    alertHash: string,
    siteId: string,
    objectId: string,
    objectName: string,
    analyticGroup: string,
    analyticId: string,
    categoryId: string,
    publishStatus: boolean,
    status: StatusIndicator,
    context: string,
    isActive: boolean,
    workflowStatus: WorkflowStatus,
    timestampStart: Date,
    timestampEnd: Date | undefined,
    lostProductionMwh: number,
    dashboardUrl: string,
    analyticName: string,
    description: string,
    title: string
  ) {
    this._publishStatus = publishStatus;
    this._workflowStatus = workflowStatus;
    this._technology = technology;
    this._alertHash = alertHash;
    this._siteId = siteId;
    this._objectId = objectId;
    this._objectName = objectName;
    this._analyticGroup = analyticGroup;
    this._analyticId = analyticId;
    this._categoryId = categoryId;
    this._status = status;
    this._context = context;
    this._isActive = isActive;
    this._timestampStart = timestampStart;
    this._timestampEnd = timestampEnd;
    this._lostProductionMwh = lostProductionMwh;
    this._dashboardUrl = dashboardUrl;
    this._analyticName = analyticName;
    this._description = description;
    this._title = title;
  }

  protected _workflowStatus: WorkflowStatus;

  get workflowStatus(): WorkflowStatus {
    return this._workflowStatus;
  }

  set workflowStatus(value: WorkflowStatus) {
    this._workflowStatus = value;
  }

  get technology(): string {
    return this._technology;
  }

  get alertHash(): string {
    return this._alertHash;
  }

  set alertHash(value: string) {
    this._alertHash = value;
  }

  get siteId(): string {
    return this._siteId;
  }

  get objectId(): string {
    return this._objectId;
  }

  get objectName(): string {
    return this._objectName;
  }

  get analyticGroup(): string {
    return this._analyticGroup;
  }

  get analyticId(): string {
    return this._analyticId;
  }

  get categoryId(): string {
    return this._categoryId;
  }

  get status(): StatusIndicator {
    return this._status;
  }

  get context(): string {
    return this._context;
  }

  get timestampStart(): Date {
    return this._timestampStart;
  }

  get timestampEnd(): Date | undefined {
    return this._timestampEnd;
  }

  get isActive(): boolean {
    return this._isActive;
  }

  get lostProductionMwh(): number {
    return this._lostProductionMwh;
  }

  get publishStatus(): boolean {
    return this._publishStatus;
  }

  get durationMilliseconds(): number {
    return (
      (this.timestampEnd ?? new Date()).getTime() -
      this.timestampStart.getTime()
    );
  }

  get dashboardUrl(): string {
    return this._dashboardUrl;
  }

  get analyticName(): string {
    return this._analyticName;
  }

  get description(): string {
    return this._description;
  }

  get title(): string {
    return this._title;
  }
}

export function alertFromResponse(response: {
  timestampEnd: string | null;
  timestampModified: string;
  technology: string;
  alertHash: string;
  alertContext: string;
  analyticGroup: string;
  ragStatus: string;
  title: string;
  isOpen: boolean;
  analyticId: string;
  timestampCreated: string;
  timestampStart: string;
  workFlowStatus: WorkflowStatus.unconfirmed;
  siteId: string;
  objectId: string;
  objectName: string;
  categoryId: string;
  lostProductionMwh: number;
  publishStatus: boolean;
  dashboardUrl: string;
  analyticName: string;
  description: string;
}): Alert {
  return new Alert(
    response.technology,
    response.alertHash,
    response.siteId,
    response.objectId,
    response.objectName,
    response.analyticGroup,
    response.analyticId,
    response.categoryId,
    response.publishStatus,
    statusFromRAG(response.ragStatus),
    response.alertContext,
    response.isOpen,
    response.workFlowStatus as WorkflowStatus,
    new Date(response.timestampStart),
    response.timestampEnd ? new Date(response.timestampEnd) : undefined,
    response.lostProductionMwh,
    response.dashboardUrl,
    response.analyticName,
    response.description,
    response.title
  );
}

export type PartialAlert = Partial<Alert>;

export function alertFromExample({
  technology = "Solar",
  alertHash = "3fa85f64-5717-4562-b3fc-2c963f66afab",
  siteId = "eng_cf",
  objectId = "eng_cf_inv_3.2",
  objectName = "Inv 3.2",
  analyticGroup = "inverter-health",
  analyticId = "inverter-system-efficiency",
  categoryId = "Inverter System Efficiency [long-term self comparison]",
  publishStatus = true,
  status = StatusIndicator.Red,
  context = "Some random text",
  isActive = false,
  workflowStatus = WorkflowStatus.unconfirmed,
  timestampStart = parseISO("2022-06-15T12:52:01.176+00:00"),
  timestampEnd = undefined,
  lostProductionMwh = 0,
  dashboardUrl = "",
  analyticName = "Inverter System Efficiency",
  description = "",
  title = ""
}: PartialAlert): Alert {
  return new Alert(
    technology,
    alertHash,
    siteId,
    objectId,
    objectName,
    analyticGroup,
    analyticId,
    categoryId,
    publishStatus,
    status,
    context,
    isActive,
    workflowStatus,
    timestampStart,
    timestampEnd,
    lostProductionMwh,
    dashboardUrl,
    analyticName,
    description,
    title
  );
}
