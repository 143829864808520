import React from "react";
import { StatusIndicator } from "../models/StatusIndicator";
import { parseISO } from "date-fns";
import {
  InMemoryStatusRepository,
  StatusRepository
} from "../repositories/StatusRepo";
import { Status } from "../models/Status";

export const StatusRepoContext = React.createContext<StatusRepository>(
  new InMemoryStatusRepository({
    eng_par: [
      new Status(
        "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "eng_par",
        "Inverter 3.2",
        "Inv 3.2",
        "inverter-health",
        "inverter-system-efficiency",
        "Inverter System Efficiency",
        "Inverter System Efficiency [long-term self comparison]",
        StatusIndicator.Red,
        0.0,
        "%",
        "Inverter system efficiency",
        false,
        false,
        "hello",
        parseISO("2022-06-15T12:52:01.176+00:00"),
        parseISO("2022-06-15T13:52:01.176+00:00")
      ),
      new Status(
        "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "eng_par",
        "Inverter 3.2",
        "Inv 3.2",
        "inverter-health",
        "inverter-system-efficiency",
        "Inverter System Efficiency",
        "Inverter System Efficiency [long-term self comparison]",
        StatusIndicator.Red,
        0.0,
        "%",
        "Inverter system efficiency",
        false,
        false,
        "hello",
        parseISO("2022-06-15T12:52:01.176+00:00"),
        parseISO("2022-06-15T13:52:01.176+00:00")
      )
    ]
  })
);
