export enum StatusIndicator {
  Green,
  Amber,
  Red,
  Grey,
  Unknown
}

export const mapStatusFromNumberToEnum = (
  statusAsNumber: number
): StatusIndicator => {
  const statusAsString = StatusIndicator[statusAsNumber];
  const statusKey = statusAsString as keyof typeof StatusIndicator;
  const status = StatusIndicator[statusKey];
  return status;
};

export const mapStatusToLabel = (status: StatusIndicator): string => {
  switch (status) {
    case StatusIndicator.Green:
      return "Ok";
    case StatusIndicator.Amber:
      return "Minor";
    case StatusIndicator.Red:
      return "Major";
    case StatusIndicator.Grey:
      return "Grey";
    case StatusIndicator.Unknown:
      return "Unknown";
    default:
      return "Unknown";
  }
};

export function statusFromRAG(rag: string): StatusIndicator {
  switch (rag.toUpperCase()) {
    case "RED":
      return StatusIndicator.Red;
    case "AMBER":
      return StatusIndicator.Amber;
    case "GREEN":
      return StatusIndicator.Green;
    case "GREY":
      return StatusIndicator.Grey;
    default:
      return StatusIndicator.Unknown;
  }
}

export function ragFromStatus(status: StatusIndicator): string {
  switch (status) {
    case StatusIndicator.Unknown:
      return "UNKNOWN";
    case StatusIndicator.Green:
      return "GREEN";
    case StatusIndicator.Amber:
      return "AMBER";
    case StatusIndicator.Red:
      return "RED";
    case StatusIndicator.Grey:
      return "GREY";
  }
}
