import { AssetConfig } from "../models/SiteConfig";
import { SiteShortConfig } from "../models/SiteShortConfig";
import { Status } from "../models/Status";

const solarObjectOrder = [
  "site",
  "pyranometer",
  "inverter",
  "stringbox",
  "dcstring"
];

const windObjectOrder = ["Windfarm", "Power Quality Meter", "Turbine"];

export const sortAssetListByAssetType = (
  selectedSite: SiteShortConfig | undefined,
  assets: AssetConfig[]
) => {
  if (selectedSite === undefined) return assets;
  const objectOrder = selectedSite.isSolar ? solarObjectOrder : windObjectOrder;

  const knownAssets = [...Array(objectOrder.length).keys()]
    .map(idx => assets.filter(a => a.type === objectOrder[idx]))
    .flat();

  const unknownAssets = assets.filter(a => !objectOrder.includes(a.type));
  return unknownAssets.concat(knownAssets);
};

export const sortStatusListByAssetType = (
  sortedAssets: AssetConfig[],
  statuses: Status[]
) => {
  const sortedStatuses = sortedAssets
    .map(asset => statuses.filter(s => s.objectId === asset.objectId))
    .flat();

  return sortedStatuses;
};

function getAssetIdSortComponents(assetId: string): Array<string | number> {
  return Array.from(assetId.matchAll(/([^\d]+|\d+)/g))
    .map(m => m[0])
    .map(m => (m.match(/\d+/g) ? Number(m) : m));
}

export function compareAssetIds(a: string, b: string): number {
  const aParts = getAssetIdSortComponents(a);
  const bParts = getAssetIdSortComponents(b);

  for (let i = 0; i < Math.min(aParts.length, bParts.length); i++) {
    const aPart = aParts[i];
    const bPart = bParts[i];
    if (typeof aPart === "number" && typeof bPart === "number") {
      const result = aPart - bPart;
      if (result !== 0) {
        return result;
      }
      continue;
    }

    const result = String(aPart).localeCompare(String(bPart));
    if (result !== 0) {
      return result;
    }
  }

  return aParts.length - bParts.length;
}
