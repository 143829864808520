import {
  Cell,
  Label,
  PrimaryButton,
  TextArea
} from "@res/smart-ui-component-library";
import LoadingSpinner from "../../../components/layout/LoadingSpinner";
import InsightComment from "../../../components/insight/InsightComment";
import { FormEvent, useEffect, useState } from "react";
import { AlertComment } from "../../../models/AlertComment";
import { useMsal } from "@azure/msal-react";
import { InsightDto } from "../../../repositories/InsightRepo";
import { AlertRepository } from "../../../repositories/AlertRepo";

interface RecommendationSectionProps {
  theme: string;
  alertRepo: AlertRepository;
  setWorkingInsight: (insight: InsightDto) => void;
  workingInsight: InsightDto;
}

export default function RecommendationSection({
  theme,
  alertRepo,
  setWorkingInsight,
  workingInsight
}: Readonly<RecommendationSectionProps>) {
  const userId = useMsal().accounts[0]?.idTokenClaims?.oid;

  const [comments, setComments] = useState<AlertComment[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [newCommentText, setNewCommentText] = useState("");

  useEffect(() => {
    const fetchComments = async () => {
      setIsLoading(true);
      const comments = await alertRepo.getComments(
        workingInsight.siteId,
        workingInsight.id
      );
      setComments(comments);
      setIsLoading(false);
    };
    fetchComments();
  }, [alertRepo, workingInsight.id, workingInsight.siteId]);

  const addNewComment = async (e: FormEvent<HTMLFormElement>) => {
    if (newCommentText.length === 0) return;
    e.preventDefault();
    setIsSubmitting(true);
    const newComment = await alertRepo.postComment(
      workingInsight.siteId,
      workingInsight.id,
      newCommentText
    );
    setComments([...comments, newComment]);
    setNewCommentText("");
    setIsSubmitting(false);
  };

  const handleRecommendationChange = (
    index: string,
    newRecommendationContent: string
  ) => {
    const currentRecommendationText = newRecommendationContent;

    const newRecommendations = workingInsight.recommendations.map(rec =>
      index === rec.id ? { ...rec, content: currentRecommendationText } : rec
    );

    // if there is only one recommendation and the content has been cleared, we return an empty array
    setWorkingInsight({
      ...workingInsight,
      recommendations:
        newRecommendations.length === 1 && newRecommendationContent === ""
          ? []
          : newRecommendations
    });
  };

  return (
    <Cell wLarge={7} wMedium={7} wSmall={7}>
      <div style={{ position: "relative", height: "100%" }}>
        <Label htmlFor="" style={{ marginBottom: "0.5rem" }}>
          Recommendations
        </Label>
        {workingInsight.recommendations.map(recommendation => (
          <div key={recommendation.id}>
            <TextArea
              className="recommendation"
              name={`recommendation-${recommendation.id}`}
              value={recommendation.content}
              rows={3}
              onChange={e =>
                handleRecommendationChange(recommendation.id, e.target.value)
              }
              placeholder="Add recommendation"
            />

            <form onSubmit={addNewComment}>
              <div className="insight-form-container">
                <Label
                  htmlFor=""
                  style={{ marginBottom: "0.5rem", marginTop: "0.25rem" }}
                >
                  Recommendation Comments
                </Label>
                <div
                  className={`comment-history-container ${theme} insight-input`}
                  style={{
                    height: comments.length === 0 ? "100px" : "200px"
                  }}
                >
                  {isLoading && <LoadingSpinner />}
                  {comments.map((c, index) => (
                    <InsightComment
                      key={c.commentId}
                      workingInsight={workingInsight}
                      alertRepo={alertRepo}
                      userId={userId}
                      comment={c}
                      comments={comments}
                      setComments={setComments}
                      index={index}
                    />
                  ))}
                  {!isLoading && comments.length === 0 && (
                    <p
                      aria-label="comment text"
                      className="comment-text"
                      style={{ marginLeft: "0.5rem" }}
                    >
                      No comments.
                    </p>
                  )}
                </div>
                <TextArea
                  name={"comment-text"}
                  label={"New Comment"}
                  value={newCommentText}
                  onChange={e => setNewCommentText(e.target.value)}
                  rows={3}
                />
                <div className="action-buttons">
                  <PrimaryButton
                    disabled={isSubmitting || newCommentText.length === 0}
                    icon="check-circle-solid"
                    useUnoStyling
                    style={{ marginTop: "0.5rem" }}
                  >
                    {!isSubmitting && <>Add Comment</>}
                    {isSubmitting && <>Saving</>}
                  </PrimaryButton>
                </div>
              </div>
            </form>
          </div>
        ))}
      </div>
    </Cell>
  );
}
