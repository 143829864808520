import { PropsWithChildren } from "react";
import "./Stack.scss";
export function Stack({
  direction,
  children,
  gap
}: PropsWithChildren<{
  direction: "horizontal" | "vertical";
  gap?: 1 | 2 | 3;
}>) {
  const gapClass = gap ? `gap-${gap}` : "";
  const classes = ["stack", direction, gapClass].join(" ");

  return <div className={classes}>{children} </div>;
}
