import { ReactComponent as SolarIcon } from "../../assets/solar-icon.svg";
import { ReactComponent as WindIcon } from "../../assets/wind-icon.svg";
import { ReactComponent as StorageIcon } from "../../assets/storage-icon.svg";

export const TechnologyIcon = ({ technology }: { technology: string }) => {
  const iconSize = "45px";
  const storageIconSize = "37px";

  if (technology.toLowerCase() === "solar") {
    return <SolarIcon title="Solar" width={iconSize} height={iconSize} />;
  } else if (technology.toLowerCase() === "wind") {
    return <WindIcon title="Wind" width={iconSize} height={iconSize} />;
  } else {
    return (
      <StorageIcon
        title="Storage"
        width={storageIconSize}
        height={storageIconSize}
      />
    );
  }
};
