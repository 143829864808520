import { supportEmailAddress } from "../../pages/About";

export const mapTableauErrorCode = (
  code: number,
  userEmailAddress: string | undefined
) => {
  if (code === 10028 || code === 16) {
    return `Content cannot be loaded as no Tableau license has been assigned to ${userEmailAddress ??
      "your account"}. Please contact ${supportEmailAddress} to request a license.`;
  }
  return `Content could not be loaded. Please contact ${supportEmailAddress} with error code ${code}.`;
};
