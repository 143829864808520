import {
  Cell,
  LayoutGrid,
  PrimaryButton,
  SecondaryButton
} from "@res/smart-ui-component-library";
import Layout from "../../components/Layout";
import { Paper } from "../../components/Paper";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";
import { InsightRepoContext } from "../../contexts/InsightRepoContext";
import {
  InsightAnalysisDto,
  InsightCreateRequest,
  InsightDto,
  InsightUpdateRequest
} from "../../repositories/InsightRepo";
import LoadingSpinner from "../../components/layout/LoadingSpinner";
import { useNavigate, useParams } from "react-router-dom";
import { AlertRepoContext } from "../../contexts/AlertRepoContext";
import { Alert } from "../../models/Alert";
import { AlertTable } from "../../components/alerts/table/AlertTable";
import "./Insight.scss";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { compareAssetIds } from "../../utils/SortUtils";
import { AssetConfig } from "../../models/SiteConfig";
import SummarySection from "./components/SummarySection";
import RecommendationSection from "./components/RecommendationSection";
import AnalysisSection from "./components/AnalysisSection";
import { SelectedSiteContext } from "../../contexts/SelectedSiteContext";

export default function Insight(): JSX.Element {
  const theme = useContext(ThemeContext);
  const insightRepo = useContext(InsightRepoContext);
  const alertRepo = useContext(AlertRepoContext);

  const [alerts, setAlerts] = useState<Alert[]>([]);

  const [isEdited, setIsEdited] = useState<boolean>(false);

  const [workingInsight, setWorkingInsight] = useState<
    InsightDto | undefined
  >();
  const [isSaving, setIsSaving] = useState(false);
  const [analyses, setAnalyses] = useState<InsightAnalysisDto[]>([]);
  const [siteDevices, setSiteDevices] = useState<AssetConfig[]>();
  const params = useParams();
  const navigate = useNavigate();

  const insightId = params.insightId as string;
  const isNewInsight = insightId === "new";
  const isLoading = !workingInsight;

  const updateWorkingInsight = (insight: InsightDto) => {
    setWorkingInsight(insight);
    setIsEdited(true);
  };

  const deleteAnalysis = useCallback(
    async (analysis: InsightAnalysisDto) => {
      await insightRepo.deleteAnalysis(insightId, analysis.id);
      const analyses = await insightRepo.listAnalyses(insightId);
      setAnalyses(analyses);
    },
    [insightId, insightRepo]
  );

  const updateAnalysis = useCallback(
    async (analysis: InsightAnalysisDto, content: string) => {
      await insightRepo.updateAnalysis(insightId, analysis.id, content);
      const analyses = await insightRepo.listAnalyses(insightId);
      setAnalyses(analyses);
    },
    [insightId, insightRepo]
  );

  const retrieveSiteDevices = (siteId: string) => {
    alertRepo.getSite(siteId).then(siteConfig => {
      if (!siteConfig) return;
      const devicesOnSite = [...siteConfig.objectConfigs]
        .sort((a, b) => compareAssetIds(a.objectId, b.objectId))
        .map(
          object => ({ ...object, siteId: siteConfig.siteId } as AssetConfig)
        );
      setSiteDevices(devicesOnSite);
    });
  };

  const retrieveSiteDevicesFn = useCallback(retrieveSiteDevices, [alertRepo]);
  const [selectedSite, setSelectedSite] = useContext(SelectedSiteContext);

  useEffect(() => {
    if (!selectedSite || !selectedSite.siteId) return;
    retrieveSiteDevicesFn(selectedSite.siteId);
  }, [selectedSite, alertRepo, retrieveSiteDevicesFn]);

  const stubInsight = useMemo(
    () => ({
      id: "",
      siteId: "",
      recommendations: [],
      alertIds: [],
      title: "",
      summary: "",
      status: "draft",
      timestampCreated: new Date().toISOString(),
      timestampModified: new Date().toISOString(),
      devices: [],
      impact: 0,
      impactUnit: "MWh"
    }),
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      setWorkingInsight(undefined);
      setAlerts([]);
      setAnalyses([]);
      if (isNewInsight) {
        setWorkingInsight({ ...stubInsight });
      } else {
        const [insight, analyses] = await Promise.all([
          insightRepo.getInsight(insightId),
          insightRepo.listAnalyses(insightId)
        ]);

        retrieveSiteDevicesFn(insight.siteId);

        if (insight?.alertIds.length) {
          const response = await Promise.all(
            insight.alertIds.map(alertId =>
              alertRepo.getAlert(insight.siteId, alertId)
            )
          );
          const alerts = response.filter(a => a) as Alert[];
          setAlerts(alerts);
        }
        setWorkingInsight(insight);
        setAnalyses(analyses);
      }
    };
    fetchData();
  }, [
    insightRepo,
    insightId,
    alertRepo,
    isNewInsight,
    retrieveSiteDevicesFn,
    stubInsight
  ]);

  useEffect(() => {
    if (workingInsight?.recommendations?.length === 0) {
      setWorkingInsight({
        ...workingInsight,
        recommendations: [{ id: "", content: "" }]
      });
    }
  }, [workingInsight]);

  const createInsight = async (insight: InsightDto) => {
    if (!selectedSite) throw new Error("No site selected");
    const request: InsightCreateRequest = {
      ...insight
    };
    return await insightRepo.createManual(selectedSite?.siteId, request);
  };

  const updateInsight = async (insight: InsightDto) => {
    const request: InsightUpdateRequest = {
      ...insight,
      recommendations: insight.recommendations
        .filter(r => r.content)
        .map(r => ({ ...r, id: r.id || undefined }))
    };
    return await insightRepo.updateInsight(insight.id, request);
  };

  const saveChanges = async (insight: InsightDto) => {
    setIsSaving(true);

    const response = isNewInsight
      ? await createInsight(insight)
      : await updateInsight(insight);

    setWorkingInsight(response);
    setIsEdited(false);
    setIsSaving(false);

    if (isNewInsight) {
      navigate(`/insights/insight/${response.id}`);
    }
  };

  const submitAnalysis = async (content: string) => {
    await insightRepo.createAnalysis(insightId, content);
    const analyses = await insightRepo.listAnalyses(insightId);
    setAnalyses(analyses);
  };

  return (
    <Layout pageName="Insight">
      <Paper>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <LayoutGrid>
              <Cell wLarge={5} wMedium={5} wSmall={5}>
                <SummarySection
                  theme={theme}
                  workingInsight={workingInsight}
                  setWorkingInsight={updateWorkingInsight}
                  siteDevices={siteDevices}
                  isNewInsight={isNewInsight}
                />

                <div
                  style={{
                    marginTop: "2rem",
                    display: "flex",
                    justifyContent: "space-between"
                  }}
                >
                  <SecondaryButton
                    disabled={!isEdited || !workingInsight || isSaving}
                    onClick={() => {
                      setWorkingInsight({ ...stubInsight });
                      setSelectedSite(undefined);
                      setIsEdited(false);
                    }}
                  >
                    {workingInsight ? "Discard Insight" : "Discard Changes"}
                  </SecondaryButton>
                  <PrimaryButton
                    style={{ marginLeft: "0.5em" }}
                    icon="check-circle-solid"
                    onClick={() => saveChanges(workingInsight)}
                    disabled={
                      !isEdited ||
                      isSaving ||
                      (isNewInsight && !workingInsight.siteId)
                    }
                  >
                    {!isSaving ? (
                      <>{!workingInsight.id ? "Create" : "Save"}</>
                    ) : (
                      <LoadingSpinner showText={false} />
                    )}
                  </PrimaryButton>
                </div>
              </Cell>

              {!isNewInsight && workingInsight.id && (
                <RecommendationSection
                  theme={theme}
                  alertRepo={alertRepo}
                  setWorkingInsight={updateWorkingInsight}
                  workingInsight={workingInsight}
                />
              )}
            </LayoutGrid>
          </>
        )}
      </Paper>

      {workingInsight?.id && (
        <Paper>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <AnalysisSection
              theme={theme}
              analyses={analyses}
              updateAnalysis={updateAnalysis}
              deleteAnalysis={deleteAnalysis}
              submitAnalysis={submitAnalysis}
              setWorkingInsight={updateWorkingInsight}
              workingInsight={workingInsight}
            />
          )}
        </Paper>
      )}

      {alerts.length !== 0 && (
        <Paper>
          <AlertTable
            allAlerts={alerts}
            isOpen={undefined}
            isAlertActions={false}
            setIsOpen={() => {}}
          />
        </Paper>
      )}
    </Layout>
  );
}
