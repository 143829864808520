export interface DashboardOption {
  label: string;
  dashboardId: string;
}

export class Dashboard {
  private readonly analyticGroupId: string;
  private readonly dashboardId: string | undefined;
  private readonly dashboardOptions: DashboardOption[] | undefined;

  constructor({
    analyticGroupId,
    dashboardId,
    dashboardOptions
  }: {
    analyticGroupId: string;
    dashboardId?: string;
    dashboardOptions?: DashboardOption[];
  }) {
    this.analyticGroupId = analyticGroupId;
    this.dashboardId = dashboardId;
    this.dashboardOptions = dashboardOptions;
  }

  hasOptions(): boolean {
    if (this.dashboardOptions === undefined) return false;
    return Array.isArray(this.dashboardOptions);
  }

  getAnalyticGroupId(): string {
    return this.analyticGroupId;
  }

  getDashboardId(): string {
    if (this.hasOptions()) throw new Error("Dashboard has options");
    if (this.dashboardId === undefined) throw new Error("Dashboard has no id");
    return this.dashboardId || "";
  }

  getDashboardOptions(): DashboardOption[] {
    if (!this.hasOptions()) throw new Error("Dashboard has no options");
    return this.dashboardOptions || [];
  }
}
