import { PrimaryButton, Tooltip } from "@res/smart-ui-component-library";
import { useState } from "react";

export function ShareButton() {
  const [showCopiedConfirmation, setShowCopiedConfirmation] = useState(false);

  const shareButton = (
    <PrimaryButton
      icon={showCopiedConfirmation ? "check" : "copy"}
      onClick={async () => {
        await navigator.clipboard.writeText(document.location.href);
        setShowCopiedConfirmation(true);
        setTimeout(() => setShowCopiedConfirmation(false), 3000);
      }}
      useUnoStyling
    >
      Share
    </PrimaryButton>
  );

  return (
    <div className="share-button-container">
      {showCopiedConfirmation ? (
        <Tooltip delay={0} openByDefault={true} content="Link copied!">
          {shareButton}
        </Tooltip>
      ) : (
        shareButton
      )}
    </div>
  );
}
