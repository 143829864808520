import { Icon } from "@res/smart-ui-component-library";
import { Tooltip } from "@res/smart-ui-component-library";
import "./UnknownIndicator.scss";

export function UnknownIndicator({ withTooltip }: { withTooltip: boolean }) {
  const icon = (
    <Icon
      aria-label="Unknown"
      role="img"
      className="icon-gray"
      icon="question-circle"
    />
  );

  if (withTooltip) {
    return (
      <Tooltip content="Unknown" delay={100} direction="bottom">
        {icon}
      </Tooltip>
    );
  }
  return icon;
}
