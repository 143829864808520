import { useContext, useState } from "react";
import Layout from "../components/Layout";
import { AlertTable } from "../components/alerts/table/AlertTable";
import { CounterHero } from "../components/alerts/hero/CounterHero";
import { useAlertList } from "../hook/useAlertList";
import { useAlertTypeCounts } from "../hook/useAlertTypeCounts";
import { SelectedPortfolioContext } from "../contexts/SelectedPortfolioContext";
import { usePortfolio } from "../hook/usePortfolioSites";

export default function Alerts() {
  const { selectedPortfolio } = useContext(SelectedPortfolioContext);
  const portfolio = usePortfolio(selectedPortfolio?.id);

  const [isOpen, setIsOpen] = useState<boolean | undefined>(true);

  const { alerts, isLoading } = useAlertList(selectedPortfolio?.id, isOpen);
  const alertTypeCounts = useAlertTypeCounts(alerts ?? []);

  const shouldCounterHero =
    portfolio?.sites?.every(s => /solar/i.test(s.technology)) ?? false;

  return (
    <Layout pageName="Alerts">
      {alerts && shouldCounterHero && <CounterHero {...alertTypeCounts} />}

      <AlertTable
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        allAlerts={alerts}
        isAlertActions={false}
        areAlertsLoading={isLoading}
        portfolioSelected={selectedPortfolio != null}
      />
    </Layout>
  );
}
