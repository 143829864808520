export const formatDays = (days: number): string => {
  if (days === 0) return "0d";
  const seconds = days * 86400;

  const numDays = Math.floor(seconds / 86400);
  const numHours = Math.floor((seconds % 86400) / 3600);
  const numMinutes = Math.floor((seconds % 3600) / 60);
  const numMinutesRoundedToNearestFive = Math.ceil(numMinutes / 5) * 5;

  const daysText = numDays > 0 ? numDays.toString() + "d " : "";
  const hoursText = numHours > 0 ? numHours.toString() + "h " : "";
  const minutesText =
    numMinutesRoundedToNearestFive > 0
      ? numMinutesRoundedToNearestFive.toString() + "m"
      : "";

  const formattedLostHours = daysText + hoursText + minutesText;
  return formattedLostHours.trim();
};
