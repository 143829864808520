import React from "react";
import {
  AlertRepository,
  InMemoryAlertRepository
} from "../repositories/AlertRepo";
import { Alert, WorkflowStatus } from "../models/Alert";
import { StatusIndicator } from "../models/StatusIndicator";
import { parseISO } from "date-fns";
import { createSiteShortConfig } from "../models/SiteShortConfig";

export const AlertRepoContext = React.createContext<AlertRepository>(
  new InMemoryAlertRepository(
    {
      eng_par: [
        new Alert(
          "Solar",
          "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          "eng_par",
          "Inverter 3.2",
          "Inv 3.2",
          "inverter-health",
          "inverter-health",
          "inverter-system-efficiency",
          true,
          StatusIndicator.Red,
          "Inverter system efficiency",
          false,
          WorkflowStatus.unconfirmed,
          parseISO("2022-06-15T12:52:01.176+00:00"),
          parseISO("2022-06-15T13:52:01.176+00:00"),
          0,
          "",
          "Inverter System Efficiency",
          "",
          ""
        ),
        new Alert(
          "Solar",
          "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          "eng_par",
          "Inverter 3.2",
          "Inv 3.2",
          "inverter-health",
          "inverter-health",
          "inverter-system-efficiency",
          true,
          StatusIndicator.Red,
          "Inverter system efficiency",
          false,
          WorkflowStatus.unconfirmed,
          parseISO("2022-06-15T12:52:01.176+00:00"),
          parseISO("2022-06-15T13:52:01.176+00:00"),
          0,
          "",
          "Inverter System Effifiency",
          "",
          ""
        )
      ]
    },
    {
      "existing-hash": [
        {
          commentId: "4401421c-fd66-4d50-86a3-535fc06b3909",
          content: "test comment pls ignore",
          userId: "Unknown",
          userName: "Unknown",
          timestampCreated: "1985-10-26T09:00:00Z"
        }
      ]
    },
    "unknown",
    () => new Date().toISOString(),
    [
      createSiteShortConfig("Parsonage", "eng_par", "Solar"),
      createSiteShortConfig("Churchtown Farm", "eng_cf", "Solar")
    ]
  )
);
