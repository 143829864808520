import { useContext, useEffect, useState } from "react";
import Layout from "../components/Layout";
import { Paper } from "../components/Paper";
import { useParams } from "react-router-dom";
import { AlertRepoContext } from "../contexts/AlertRepoContext";
import { AlertActions as AlertActionsComponent } from "../components/alerts/actions/AlertActions";
import { Alert } from "../models/Alert";
import { AlertTable } from "../components/alerts/table/AlertTable";
import { TableauContainer } from "../components/TableauContainer";
import LoadingSpinner from "../components/layout/LoadingSpinner";
import { Cell, PrimaryButton } from "@res/smart-ui-component-library";
import NoDataFoundText from "../components/layout/NoDataFoundText";

import { TokenRepoContext } from "../contexts/TokenRepoContext";
import { Token } from "../models/Token";
import {
  formatDateTimeForTableau,
  formatSensorValue
} from "../utils/TableauUtils";
import { DiagnosticDashboardContainer } from "../components/ShinyContainer";

function extractDashboardRenderingParameters(alert: Alert | undefined) {
  if (alert === undefined) {
    return undefined;
  }
  if (alert.dashboardUrl === undefined) {
    return undefined;
  }

  if (alert.dashboardUrl.startsWith("iframe+")) {
    return {
      destination: "iframe",
      url: alert.dashboardUrl.slice("iframe+".length),
      diagnosticName: "",
      tableauParams: []
    };
  } else {
    const diagnosticSensorGroup = getSensorGroup(alert);

    const objects =
      alert?.analyticName !== "Data Coverage"
        ? getObjectsToShow(alert?.objectName)
        : undefined;

    const flaggingFields = getFlaggingFields(alert?.dashboardUrl);

    const temperatureFlaggingFields = flaggingFields?.join(",");

    const flaggingFieldOne = flaggingFields ? flaggingFields[0] : "";
    const flaggingFieldTwo = flaggingFields ? flaggingFields[1] : "";
    const flaggingFieldThree =
      flaggingFields && flaggingFields.length > 2 ? flaggingFields[2] : "";

    const startTime = formatDateTimeForTableau(alert?.timestampStart);
    const endTime = formatDateTimeForTableau(alert?.timestampEnd);

    return {
      destination: "tableau",
      url: alert.dashboardUrl,
      diagnosticName:
        alert.technology === "Solar"
          ? getSolarDiagnosticName(alert.analyticGroup)
          : getWindDiagnosticName(alert),
      tableauParams: alert
        ? [
            {
              paramName: "Site",
              value: alert.siteId
            },
            {
              paramName: "Metric Parameter",
              value:
                alert.analyticName === "Long Term Inverter Availability"
                  ? "Inverter System Efficiency"
                  : alert.analyticName
            },
            {
              paramName: "Start Time",
              value: startTime
            },
            {
              paramName: "End Time",
              value: endTime
            },
            {
              paramName: "Sensor 2 Start Time",
              value: startTime
            },
            {
              paramName: "Sensor 2 End Time",
              value: endTime
            },
            {
              paramName: "Day 0",
              value: endTime
            },
            {
              paramName: "Reference Time",
              value: startTime
            },
            {
              paramName: "Turbine Id Friendly",
              value: objects
            },
            {
              paramName: "INVERTER_ID_FRIENDLY",
              value: objects
            },
            {
              paramName: "Sensor",
              value: temperatureFlaggingFields
            },
            {
              paramName: "Time Series 1",
              value: flaggingFieldOne
            },
            {
              paramName: "Time Series 2",
              value: flaggingFieldTwo
            },
            {
              paramName: "Time Series 3",
              value: flaggingFieldThree
            },
            {
              paramName: "Sensor 1",
              value: flaggingFieldOne
            },
            {
              paramName: "Sensor 2",
              value: flaggingFieldTwo
            },
            { paramName: "Highlight Object", value: alert.objectName },
            { paramName: "Sensor Group", value: diagnosticSensorGroup }
          ]
        : []
    };
  }
}

function getWindDiagnosticName(alert: Alert): string {
  if (alert.dashboardUrl === "") {
    return "";
  }
  if (alert.analyticId === "invalid_data") {
    return "WindDataQuality/DataQuality-FrontPage";
  }
  if (alert.analyticGroup === "grid_compliance") {
    return `GridCompliance/${alert.analyticId}`;
  }

  const [, infoSubstring] = alert.dashboardUrl.split("/restechnical/views/");
  const namePart = infoSubstring.split("?")[0];
  return namePart;
}

function getSolarDiagnosticName(analyticGroup: string): string {
  const diagnosticNames: Record<string, string> = {
    "inverter-health": "InverterHealth/TimeSeriesInverterHealth",
    "sensor-health": "PyranometerCalibration/RatioCorrelation",
    "data-quality": "DataQuality/FrontPage"
  };

  return diagnosticNames[analyticGroup];
}

function getSensorGroup(alert: Alert | undefined): string | undefined {
  if (alert && alert.dashboardUrl && alert.analyticGroup === "temperature") {
    const [, infoSubstring] = alert.dashboardUrl.split("/restechnical/views/");
    const groupPart = infoSubstring.split("?")[1];
    const diagnosticSensorGroup = groupPart
      .split("Sensor+Group=")[1]
      .split("&")[0];
    return diagnosticSensorGroup;
  }
}

function getFlaggingFields(
  dashboardUrl: string | undefined
): (string | undefined)[] {
  const queryString = dashboardUrl?.split("?")[1];
  const urlParams = new URLSearchParams(queryString);
  const sensorValues = urlParams.get("Sensor");

  if (sensorValues) {
    const sensorValuesArray = sensorValues.split(",");
    const sensorValuesArrayFormatted = sensorValuesArray.map(sensor =>
      formatSensorValue(sensor)
    );
    return sensorValuesArrayFormatted;
  }
  return [];
}

function getObjectsToShow(objectName: string | undefined): string | undefined {
  const alertingObjectFirstName = objectName?.split(", ")[0];
  const leadingLetters = alertingObjectFirstName?.split(/\d+$/)[0];
  const number = alertingObjectFirstName?.match(/\d+$/)?.[0];

  if (!number) {
    return undefined;
  }

  const numberInt = parseInt(number);

  const objects = [
    ...Array.from({ length: 7 }, (_, i) => i - 3).map(
      offset => leadingLetters + String(numberInt + offset)
    ),
    ...Array.from({ length: 7 }, (_, i) => i - 3).map(
      offset => leadingLetters + "0" + String(numberInt + offset)
    )
  ].join(",");
  return objects;
}

export default function AlertActions() {
  const [loaded, setLoaded] = useState(false);
  const [alert, setAlert] = useState<undefined | Alert>(undefined);
  const parameters = useParams();
  const alertRepository = useContext(AlertRepoContext);
  const tokenRepository = useContext(TokenRepoContext);

  const [tableauToken, setTableauToken] = useState<undefined | Token>(
    undefined
  );

  useEffect(() => {
    tokenRepository
      .getTableauToken()
      .then(value => {
        setTableauToken(value);
      })
      .catch(() => {
        console.log("Failed to load Tableau token.");
      });
  }, [tokenRepository]);

  useEffect(() => {
    alertRepository
      .getAlert(parameters.site, parameters.alertId)
      .then(value => {
        setAlert(value);
        setLoaded(true);
      })
      .catch(() => setLoaded(true));
  }, [alertRepository, parameters]);

  const isDataLoading = loaded === false;
  const noDataFound = loaded && alert === undefined;
  const dashboardParams = extractDashboardRenderingParameters(alert);

  return (
    <Layout pageName="Alert Actions">
      <Cell>
        {(isDataLoading || noDataFound) && (
          <>
            <Paper>
              {isDataLoading && <LoadingSpinner />}
              {noDataFound && <NoDataFoundText />}
            </Paper>
          </>
        )}
      </Cell>
      {loaded && alert && (
        <>
          <AlertTable
            allAlerts={[alert]}
            setIsOpen={() => {}}
            isOpen={true}
            isAlertActions={true}
          />
          <Paper>
            <AlertActionsComponent alert={alert}></AlertActionsComponent>
          </Paper>
          {dashboardParams?.destination === "iframe" ? (
            <Paper hideBottomPadding>
              <a
                href={dashboardParams.url}
                target="_blank"
                rel="noreferrer"
                style={{ display: "block" }}
              >
                <PrimaryButton useUnoStyling onClick={() => {}}>
                  Open Dashboard
                </PrimaryButton>
              </a>
              <DiagnosticDashboardContainer url={dashboardParams.url} />
            </Paper>
          ) : (
            dashboardParams &&
            tableauToken && (
              <Paper hideBottomPadding>
                <TableauContainer
                  name={dashboardParams?.diagnosticName}
                  params={dashboardParams?.tableauParams}
                  token={tableauToken}
                />
              </Paper>
            )
          )}
        </>
      )}
    </Layout>
  );
}
