import React, {
  PropsWithChildren,
  useContext,
  useState,
  useEffect,
  useMemo
} from "react";
import { AlertRepoContext } from "./AlertRepoContext";
import { AnalyticConfig } from "../models/AnalyticConfig";
import { getUniqueAnalyticGroups } from "../utils/AnalyticUtils";
import { AnalyticGroupConfig } from "./../models/AnalyticGroupConfig";

export const AnalyticContext = React.createContext<{
  analytics: AnalyticConfig[];
  solarAnalytics: AnalyticConfig[];
  windAnalytics: AnalyticConfig[];
  solarAnalyticGroups: AnalyticGroupConfig[];
  windAnalyticGroups: AnalyticGroupConfig[];
}>({
  analytics: [],
  solarAnalytics: [],
  windAnalytics: [],
  solarAnalyticGroups: [],
  windAnalyticGroups: []
});

export const AnalyticProvider = ({ children }: PropsWithChildren<unknown>) => {
  const alertsRepo = useContext(AlertRepoContext);
  const [analytics, setAnalytics] = useState<AnalyticConfig[]>([]);

  useEffect(() => {
    alertsRepo.getAnalytics().then(analytics => {
      setAnalytics(analytics);
    });
  }, [alertsRepo]);

  const [solarAnalytics, windAnalytics] = useMemo(() => {
    return [
      analytics.filter(analytic =>
        analytic.technology.toLowerCase().includes("solar")
      ),
      analytics.filter(analytic =>
        analytic.technology.toLowerCase().includes("wind")
      )
    ];
  }, [analytics]);

  const [solarAnalyticGroups, windAnalyticGroups] = useMemo(() => {
    return [
      getUniqueAnalyticGroups(solarAnalytics),
      getUniqueAnalyticGroups(windAnalytics)
    ];
  }, [solarAnalytics, windAnalytics]);

  return (
    <AnalyticContext.Provider
      value={{
        analytics,
        solarAnalytics,
        windAnalytics,
        solarAnalyticGroups,
        windAnalyticGroups
      }}
    >
      {children}
    </AnalyticContext.Provider>
  );
};
