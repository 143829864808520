import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
  useEffect
} from "react";
import { useParams } from "react-router-dom";
import { AlertRepoContext } from "./AlertRepoContext";
import { SiteShortConfig } from "../models/SiteShortConfig";
import keyBy from "lodash/keyBy";

interface SiteMap {
  [siteId: string]: SiteShortConfig;
}

const defaultSite: SiteShortConfig | undefined = undefined;
export const SelectedSiteContext = React.createContext<
  [
    SiteShortConfig | undefined,
    (site?: SiteShortConfig) => void,
    (siteId: string) => SiteShortConfig,
    SiteShortConfig[]
  ]
>([
  defaultSite,
  () => {
    return;
  },
  siteId => new SiteShortConfig("Site Name", "Site Id", "Solar"),
  []
]);

const constructSiteMap = (siteShortConfigs: SiteShortConfig[]) => {
  const siteMap = keyBy(siteShortConfigs, "siteId");
  return siteMap;
};

export const SelectedSiteProvider = ({
  children
}: PropsWithChildren<unknown>) => {
  const alertsRepo = useContext(AlertRepoContext);

  const params = useParams();

  const [allSites, setAllSites] = useState<SiteShortConfig[]>([]);
  const [siteMap, setSiteMap] = useState<SiteMap>({});

  const [siteShortConfig, setSiteShortConfig]: [
    SiteShortConfig | undefined,
    Dispatch<SetStateAction<SiteShortConfig | undefined>>
  ] = useState<SiteShortConfig | undefined>(defaultSite);

  useEffect(() => {
    alertsRepo.getSites().then(allSites => {
      if (allSites) {
        const newSiteMap = constructSiteMap(allSites);
        setSiteMap(newSiteMap);
        setAllSites(allSites);
      }
    });
  }, [alertsRepo]);

  useEffect(() => {
    const siteIdFromUrl = params["site"];
    if (allSites.length > 0 && siteIdFromUrl && siteShortConfig === undefined) {
      const shortConfig: SiteShortConfig | undefined = allSites.find(
        shortConfig => shortConfig && shortConfig.siteId === siteIdFromUrl
      );

      if (shortConfig) {
        setSiteShortConfig(shortConfig);
      }
    }
  }, [params, siteShortConfig, allSites]);

  const getSiteById = (siteId: string) => {
    return siteMap[siteId];
  };

  return (
    <SelectedSiteContext.Provider
      value={[siteShortConfig, setSiteShortConfig, getSiteById, allSites]}
    >
      {children}
    </SelectedSiteContext.Provider>
  );
};
