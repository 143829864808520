import { ReactComponent as MajorIcon } from "../../../assets/table/major-icon.svg";
import { Tooltip } from "@res/smart-ui-component-library";

export function RedIndicator({ withTooltip }: { withTooltip: boolean }) {
  const icon = (
    <MajorIcon aria-label="Major" role="img" width="22px" height="22px" />
  );
  if (withTooltip) {
    return (
      <Tooltip content="Major" delay={100} direction="bottom">
        {icon}
      </Tooltip>
    );
  }
  return icon;
}
