import { ReactComponent as MinorIcon } from "../../../assets/table/minor-icon.svg";
import { Tooltip } from "@res/smart-ui-component-library";

export function AmberIndicator({ withTooltip }: { withTooltip: boolean }) {
  const icon = (
    <MinorIcon aria-label="Minor" role="img" width="22px" height="22px" />
  );

  if (withTooltip) {
    return (
      <Tooltip content="Minor" delay={100} direction="bottom">
        {icon}
      </Tooltip>
    );
  }
  return icon;
}
