import Layout from "../components/Layout";
import { Card, Cell } from "@res/smart-ui-component-library";
import "./About.scss";

export const supportEmailAddress = "uno-support@res-group.com";

export default function About() {
  return (
    <Layout pageName="Help">
      <Cell>
        <Card header="Support" className="about-card">
          <div className="about-card-content">
            <span>
              For urgent issues please email{" "}
              <a
                href={`mailto:${supportEmailAddress}`}
                target="_blank"
                rel="noreferrer"
                className={"about-link"}
              >
                {supportEmailAddress}
              </a>
            </span>
          </div>
        </Card>
      </Cell>
      <Cell>
        <Card header="Documentation" className="about-card">
          <div className="about-card-content">
            <ul>
              <li>
                <a
                  href="https://docs.analytics.uno-res.com"
                  className="about-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Solar Analytics Documentation
                </a>
              </li>
              <li>
                <a
                  href="https://docs.wind.uno-res.com/"
                  className="about-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Wind Analytics Documentation
                </a>
              </li>
            </ul>
          </div>
        </Card>
      </Cell>
      <Cell>
        <Card header="Feedback" className="about-card">
          <div className="about-card-content">
            <span>
              We would love to hear your feedback on using UNO Analytics so that
              we can make the application better! Please provide feedback to the
              team{" "}
              <a
                href="https://forms.office.com/Pages/ResponsePage.aspx?id=cAZhkIAaSkOIw-VovOObxdxRKOnv1-tIoCr4vYU2NvFUMzBFUjlCWDI2WFY2QkJNMjBFM0w4OFZLMi4u"
                className="about-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
            </span>
          </div>
        </Card>
      </Cell>
    </Layout>
  );
}
