import keyBy from "lodash/keyBy";
import { SiteShortConfig } from "./models/SiteShortConfig";
import { Dashboard } from "./models/Dashboard";

export const EXPLORER_DASHBOARD = "EXPLORER";
export const DCHEALTH_DASHBOARD = "DCHEALTH";

const solarDashboards = [
  new Dashboard({
    analyticGroupId: EXPLORER_DASHBOARD,
    dashboardId:
      "https://app-uw-diagnostic-dashboards-prd.azurewebsites.net/solar/data-explorer"
  }),
  new Dashboard({
    analyticGroupId: "inverter-health",
    dashboardId:
      "https://app-uw-diagnostic-dashboards-prd.azurewebsites.net/solar/inverter-health"
  }),
  new Dashboard({
    analyticGroupId: "sensor-health",
    dashboardId:
      "https://app-uw-diagnostic-dashboards-prd.azurewebsites.net/solar/sensor-health"
  }),
  new Dashboard({
    analyticGroupId: "data-quality",
    dashboardId:
      "https://app-uw-diagnostic-dashboards-prd.azurewebsites.net/solar/data-quality"
  }),
  new Dashboard({
    analyticGroupId: DCHEALTH_DASHBOARD,
    dashboardId:
      "https://app-uw-diagnostic-dashboards-prd.azurewebsites.net/solar/dc-health"
  })
];

const windDashboards = [
  new Dashboard({
    analyticGroupId: EXPLORER_DASHBOARD,
    dashboardId:
      "https://app-uw-diagnostic-dashboards-prd.azurewebsites.net/wind/explorer"
  }),
  new Dashboard({
    analyticGroupId: "temperature",
    dashboardId:
      "https://app-uw-diagnostic-dashboards-prd.azurewebsites.net/wind/temperature"
  }),
  new Dashboard({
    analyticGroupId: "data_quality",
    dashboardId:
      "https://app-uw-diagnostic-dashboards-prd.azurewebsites.net/wind/data-quality"
  }),
  new Dashboard({
    analyticGroupId: "curtailment",
    dashboardId:
      "https://app-uw-diagnostic-dashboards-prd.azurewebsites.net/wind/curtailment"
  }),
  new Dashboard({
    analyticGroupId: "northing",
    dashboardId:
      "https://app-uw-diagnostic-dashboards-prd.azurewebsites.net/wind/northing"
  }),
  new Dashboard({
    analyticGroupId: "turbine_performance",
    dashboardId:
      "https://app-uw-diagnostic-dashboards-prd.azurewebsites.net/wind/performance"
  }),
  new Dashboard({
    analyticGroupId: "wind_sensor_health",
    dashboardId:
      "https://app-uw-diagnostic-dashboards-prd.azurewebsites.net/wind/wind-sensor-health"
  }),
  new Dashboard({
    analyticGroupId: "grid_compliance",
    dashboardId:
      "https://app-uw-diagnostic-dashboards-prd.azurewebsites.net/wind/grid-compliance"
  })
];

const analyticIdToSolarDashboardMap = keyBy(solarDashboards, dashboard =>
  dashboard.getAnalyticGroupId()
);

const analyticIdToWindDashboardMap = keyBy(windDashboards, dashboard =>
  dashboard.getAnalyticGroupId()
);

export const getDashboard = (
  selectedSite: SiteShortConfig | undefined,
  analyticGroupId: string
) => {
  const analyticGroupIdToDashboardMap = selectedSite?.isSolar
    ? analyticIdToSolarDashboardMap
    : analyticIdToWindDashboardMap;
  return analyticGroupIdToDashboardMap[analyticGroupId];
};
