import { Select } from "@res/smart-ui-component-library";

const statusOptions = [
  { value: "draft", label: "Draft" },
  { value: "open", label: "Open" },
  { value: "complete", label: "Complete" }
];
const defaultStatus = statusOptions[0];

interface StatusDropdownProps {
  setStatus: (status: string) => void;
  status: string;
}

const StatusDropdown = ({ status, setStatus }: StatusDropdownProps) => {
  const value =
    statusOptions.find(option => option.value === status) || defaultStatus;

  return (
    <Select
      className="insight-input"
      label="Status"
      name="status"
      value={value}
      options={statusOptions}
      onChange={newStatus => setStatus(newStatus.value)}
      isMulti={false}
    />
  );
};

export default StatusDropdown;
