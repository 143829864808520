import { PrimaryButton } from "@res/smart-ui-component-library";
import "./ActionButton.scss";

export function AlertAction({ onClick }: { onClick: () => void }) {
  return (
    <PrimaryButton
      className={"btn-table"}
      useUnoStyling={true}
      onClick={onClick}
      style={{
        padding: "7px 5px",
        fontWeight: "600",
        fontSize: "13px"
      }}
    >
      Actions
    </PrimaryButton>
  );
}
