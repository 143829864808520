import axios from "axios";
import { Alert } from "../models/Alert";

export interface InsightDeviceDto {
  deviceId: string;
}

export interface InsightDto {
  id: string;
  siteId: string;
  title: string;
  summary: string;
  devices: InsightDeviceDto[];
  status: string;
  impact: number;
  impactUnit: string;
  recommendations: { id: string; content: string }[];
  alertIds: string[];
  timestampCreated: string;
  timestampModified: string;
}

export interface InsightCreateRequest {
  title: string;
  summary: string;
  devices: InsightDeviceDto[];
  status: string;
  impact: number;
  impactUnit: string;
}

export interface InsightUpdateRequest {
  title: string;
  summary: string;
  devices: InsightDeviceDto[];
  status: string;
  impact: number;
  impactUnit: string;
  recommendations: { id?: string; content: string }[];
  alertIds: string[];
}

export interface InsightAnalysisDto {
  id: string;
  content: string;
  timestampCreated: string;
  userNameCreated: string;
  userIdCreated: string;
  timestampModified: string;
  userNameModified: string;
  userIdModified: string;
}

export interface InsightRepo {
  getInsight(insightId: string): Promise<InsightDto>;
  updateInsight(
    insightId: string,
    request: InsightUpdateRequest
  ): Promise<InsightDto>;
  createFromAlert(siteId: string, alertId: string): Promise<InsightDto>;
  createManual(
    siteId: string,
    insight: InsightCreateRequest
  ): Promise<InsightDto>;
  listInsights(siteIds?: string[]): Promise<InsightDto[]>;
  listAnalyses(insightId: string): Promise<InsightAnalysisDto[]>;
  createAnalysis(
    insightId: string,
    content: string
  ): Promise<InsightAnalysisDto>;
  updateAnalysis(
    insightId: string,
    analysisId: string,
    content: string
  ): Promise<InsightAnalysisDto>;
  deleteAnalysis(insightId: string, analysisId: string): Promise<void>;
}

export class HttpInsightRepo implements InsightRepo {
  private readonly baseUrl: string;
  private readonly getAuthToken: () => Promise<string | undefined>;

  constructor(
    baseUrl: string,
    getAuthToken: () => Promise<string | undefined>
  ) {
    this.baseUrl = baseUrl;
    this.getAuthToken = getAuthToken;
  }

  async getInsight(insightId: string): Promise<InsightDto> {
    const response = await axios.get(
      `${this.baseUrl}/api/insight/${encodeURIComponent(insightId)}`,
      { headers: await this.getAuthHeaders() }
    );

    return response.data;
  }

  async updateInsight(
    insightId: string,
    request: InsightUpdateRequest
  ): Promise<InsightDto> {
    const response = await axios.put(
      `${this.baseUrl}/api/insight/${encodeURIComponent(insightId)}`,
      request,
      { headers: await this.getAuthHeaders() }
    );

    return response.data;
  }

  async createFromAlert(siteId: string, alertId: string): Promise<InsightDto> {
    const response = await axios.post(
      `${this.baseUrl}/api/insight/createFromAlert/${encodeURIComponent(
        siteId
      )}/${encodeURIComponent(alertId)}`,
      null,
      { headers: await this.getAuthHeaders() }
    );

    return response.data;
  }

  async createManual(
    siteId: string,
    insight: InsightCreateRequest
  ): Promise<InsightDto> {
    const response = await axios.post(
      `${this.baseUrl}/api/insight/createManual/${encodeURIComponent(siteId)}`,
      insight,
      { headers: await this.getAuthHeaders() }
    );

    return response.data;
  }

  async listInsights(siteIds?: string[]): Promise<InsightDto[]> {
    const queryString = siteIds
      ? "?" + siteIds.map(s => `siteIds=${encodeURIComponent(s)}`).join("&")
      : "";
    const response = await axios.get(
      `${this.baseUrl}/api/insight${queryString}`,
      { headers: await this.getAuthHeaders() }
    );

    return response.data?.insights ?? [];
  }

  async listAnalyses(insightId: string): Promise<InsightAnalysisDto[]> {
    const response = await axios.get(
      `${this.baseUrl}/api/insight/${encodeURIComponent(insightId)}/analysis`,
      { headers: await this.getAuthHeaders() }
    );

    return response.data?.analyses;
  }

  async createAnalysis(
    insightId: string,
    content: string
  ): Promise<InsightAnalysisDto> {
    const response = await axios.post(
      `${this.baseUrl}/api/insight/${encodeURIComponent(insightId)}/analysis`,
      { content },
      { headers: await this.getAuthHeaders() }
    );

    return response.data;
  }

  async updateAnalysis(
    insightId: string,
    analysisId: string,
    content: string
  ): Promise<InsightAnalysisDto> {
    const response = await axios.post(
      `${this.baseUrl}/api/insight/${encodeURIComponent(
        insightId
      )}/analysis/${encodeURIComponent(analysisId)}`,
      { content },
      { headers: await this.getAuthHeaders() }
    );

    return response.data;
  }

  async deleteAnalysis(insightId: string, analysisId: string): Promise<void> {
    await axios.delete(
      `${this.baseUrl}/api/insight/${encodeURIComponent(
        insightId
      )}/analysis/${encodeURIComponent(analysisId)}`,
      { headers: await this.getAuthHeaders() }
    );
  }

  private async getAuthHeaders() {
    return { Authorization: `Bearer ${await this.getAuthToken()}` };
  }
}

export class InMemoryInsightRepo implements InsightRepo {
  private insights: InsightDto[];
  private alerts: Alert[];
  private analyses: Record<string, InsightAnalysisDto[]>;

  constructor(
    insights?: InsightDto[],
    alerts?: Alert[],
    analyses?: Record<string, InsightAnalysisDto[]>
  ) {
    this.insights = insights ?? [];
    this.alerts = alerts ?? [];
    this.analyses = analyses ?? {};
  }

  getInsight(insightId: string): Promise<InsightDto> {
    const insight = this.insights?.find(i => i.id === insightId);

    if (!insight) throw new Error("not found");
    return Promise.resolve(insight);
  }

  updateInsight(
    insightId: string,
    request: InsightUpdateRequest
  ): Promise<InsightDto> {
    const insight = this.insights?.find(i => i.id === insightId);
    if (!insight) throw new Error("not found");
    Object.assign(insight, request);
    return Promise.resolve(insight);
  }

  createFromAlert(siteId: string, alertId: string): Promise<InsightDto> {
    const alert = this.alerts.find(a => a.alertHash === alertId);
    if (!alert) throw new Error("alert missing");
    const insight: InsightDto = {
      id: `insight-${this.insights.length}`,
      alertIds: [alertId],
      devices: [{ deviceId: alert.objectId }],
      impact: alert.lostProductionMwh,
      impactUnit: "MWh",
      recommendations: [],
      siteId: siteId,
      status: "draft",
      summary: alert.description,
      timestampCreated: new Date().toISOString(),
      timestampModified: new Date().toISOString(),
      title: alert.title
    };

    this.insights.push(insight);

    return Promise.resolve(insight);
  }

  createManual(
    siteId: string,
    insight: InsightCreateRequest
  ): Promise<InsightDto> {
    const insightDto: InsightDto = {
      ...insight,
      id: `insight-${this.insights.length}`,
      alertIds: [],
      timestampCreated: new Date().toISOString(),
      timestampModified: new Date().toISOString(),
      siteId: siteId,
      recommendations: []
    };

    this.insights.push(insightDto);

    return Promise.resolve(insightDto);
  }

  listInsights(siteIds?: string[] | undefined): Promise<InsightDto[]> {
    if (siteIds) {
      return Promise.resolve(
        this.insights?.filter(i => siteIds.indexOf(i.id) !== -1) ?? []
      );
    }
    return Promise.resolve(this.insights ?? []);
  }

  listAnalyses(insightId: string): Promise<InsightAnalysisDto[]> {
    return Promise.resolve(this.analyses[insightId] ?? []);
  }

  createAnalysis(
    insightId: string,
    content: string
  ): Promise<InsightAnalysisDto> {
    const analyses = (this.analyses[insightId] =
      this.analyses[insightId] ?? []);
    const analysis: InsightAnalysisDto = {
      id: `analysis-${insightId}-${analyses.length}`,
      content,
      timestampCreated: new Date().toISOString(),
      userNameCreated: "in memory user name",
      userIdCreated: "in memory user id",
      timestampModified: new Date().toISOString(),
      userNameModified: "in memory user name",
      userIdModified: "in memory user id"
    };

    analyses.push(analysis);

    return Promise.resolve(analysis);
  }

  updateAnalysis(
    insightId: string,
    analysisId: string,
    content: string
  ): Promise<InsightAnalysisDto> {
    const existing = this.analyses[insightId]?.find(a => a.id === analysisId);
    if (!existing) throw new Error("not found");

    Object.assign(existing, {
      content,
      timestampModified: new Date().toISOString(),
      userNameModified: "in memory user name",
      userIdModified: "in memory user id"
    });

    return Promise.resolve(existing);
  }

  deleteAnalysis(insightId: string, analysisId: string): Promise<void> {
    this.analyses[insightId] = this.analyses[insightId]?.filter(
      a => a.id !== analysisId
    );
    return Promise.resolve();
  }
}
