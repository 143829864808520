import { useContext, useEffect, useState } from "react";
import { StatusRepoContext } from "../contexts/StatusRepoContext";
import { StatusSortBy } from "../repositories/StatusRepo";
import { Status } from "../models/Status";

interface StatusResponse {
  statuses: Status[];
  isLoading: boolean;
}

export function useStatusList(
  selectedSite?: string,
  sortBy?: StatusSortBy,
  isOpen?: boolean,
  timestampStart?: string
): StatusResponse {
  const [statuses, setStatuses] = useState<undefined | Status[]>();
  const [isLoading, setIsLoading] = useState(true);

  const repo = useContext(StatusRepoContext);

  useEffect(() => {
    function doCall({ isInitialLoad }: { isInitialLoad: boolean }) {
      (async () => {
        if (selectedSite) {
          isInitialLoad && setIsLoading(true);
          const newStatuses = await repo.listStatuses(
            selectedSite,
            sortBy,
            isOpen,
            timestampStart
          );
          setStatuses(newStatuses);
          isInitialLoad && setIsLoading(false);
        }
      })();
    }

    doCall({ isInitialLoad: true });

    const pollEveryMinute = 60000;
    const pollEveryHour = 60 * pollEveryMinute;

    const poller = setInterval(
      () => doCall({ isInitialLoad: false }),
      pollEveryHour
    );

    return () => clearInterval(poller);
  }, [repo, selectedSite, sortBy, isOpen, timestampStart]);

  return { statuses: statuses || [], isLoading };
}
