import React, { PropsWithChildren, useEffect, useState } from "react";
import { GlobalStyle, ThemeProvider } from "@res/smart-ui-component-library";
import { ThemeContext } from "../../contexts/ThemeContext";

function getTheme() {
  return window.matchMedia("(prefers-color-scheme: dark)").matches
    ? "dark"
    : "light";
}

export function MediaAwareThemeProvider({ children }: PropsWithChildren<{}>) {
  const [theme, setTheme] = useState<"light" | "dark">(getTheme());
  useEffect(() => {
    if (!window) {
      return;
    }
    const query = window.matchMedia("(prefers-color-scheme: dark)");
    query.addEventListener("change", e => {
      setTheme(e.matches ? "dark" : "light");
    });
  }, []);

  return (
    <ThemeContext.Provider value={theme}>
      <ThemeProvider
        theme={{
          mode: theme
        }}
      >
        <GlobalStyle />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}
