import {
  AuthenticationResult,
  EventMessage,
  EventPayload,
  EventType,
  InteractionRequiredAuthError,
  PublicClientApplication
} from "@azure/msal-browser";

import { alertsLoginRequest, msalConfig } from "./authConfig";

let _msalInstance: PublicClientApplication | undefined = undefined;

function isAuthenticationResult(
  payload: EventPayload
): payload is AuthenticationResult {
  return (payload as AuthenticationResult)?.account != null;
}

function getMsalInstance() {
  if (!_msalInstance) {
    _msalInstance = new PublicClientApplication({
      auth: {
        authority: msalConfig.auth.authority,
        knownAuthorities: msalConfig.auth.knownAuthorities || [],
        clientId: msalConfig.auth.clientId,
        redirectUri: window.origin
      }
    });

    const accounts = _msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      _msalInstance.setActiveAccount(accounts[0]);
    }

    _msalInstance.addEventCallback((event: EventMessage) => {
      if (
        event.eventType === EventType.LOGIN_SUCCESS &&
        isAuthenticationResult(event?.payload)
      ) {
        const account = event.payload.account;
        _msalInstance!.setActiveAccount(account);
      }
    });
  }
  return _msalInstance;
}

export async function getAccessTokenAlerts(): Promise<string | undefined> {
  return getAccessToken(alertsLoginRequest.scopes);
}

async function getAccessToken(
  scopes: Array<string>
): Promise<string | undefined> {
  const msalInstance = getMsalInstance();
  const account = msalInstance.getAllAccounts()[0];
  if (account) {
    try {
      const result = await msalInstance.acquireTokenSilent({
        account,
        scopes: scopes
      });
      return result?.accessToken;
    } catch (e) {
      console.warn("Failed to get access token", e);
      if (
        e instanceof InteractionRequiredAuthError ||
        (isCodedError(e) && e?.errorCode === "login_required")
      ) {
        await msalInstance.loginRedirect({
          account,
          scopes: scopes
        });
      } else if (isCodedError(e) && e?.errorCode !== "invalid_client") {
        await msalInstance.acquireTokenRedirect({
          account,
          scopes: scopes
        });
      }
    }
  }
  return undefined;
}

function isCodedError(error: any): error is { errorCode: string | undefined } {
  return typeof error == "object";
}

export { getMsalInstance };
