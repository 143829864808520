import { MsalAuthenticationResult } from "@azure/msal-react/dist/hooks/useMsalAuthentication";
import { ErrorLayout } from "../components/ErrorLayout";
import React from "react";

export function LoginError({ error }: MsalAuthenticationResult) {
  return (
    <ErrorLayout pageName={"Login Error"}>
      <h1 className={"text-danger"}>{error?.name}</h1>
      <p>{error?.message}</p>
      <code>{JSON.stringify(error)}</code>
    </ErrorLayout>
  );
}
