import { FilterStatus, SelectOption } from "@res/smart-ui-component-library";
import { Alert } from "../../../../models/Alert";
import { Portfolio } from "../../../../models/Portfolio";
import { SiteShortConfig } from "../../../../models/SiteShortConfig";

export const getFilteredTechnologies = (columnFilterStatus: FilterStatus[]) => {
  const technologyColumnFilters = columnFilterStatus.find(
    column => column.name === "technology"
  );
  const filteredTechnologies =
    technologyColumnFilters?.value?.map((item: SelectOption) => item.value) ??
    [];
  return filteredTechnologies;
};

export const getFilteredSiteNames = (columnFilterStatus: FilterStatus[]) => {
  const siteNameColumnFilters = columnFilterStatus.find(
    column => column.name === "asset"
  );
  const filteredSiteNames =
    siteNameColumnFilters?.value?.map((item: SelectOption) => item.value) ?? [];
  return filteredSiteNames;
};

export const getSitesAfterFilter = (
  portfolio: Portfolio | undefined,
  filteredTechnologies: string[]
) => {
  const uniqueSiteNames =
    portfolio?.sites
      .filter(site => {
        if (filteredTechnologies.length === 0) return true; // return all sites if no technology filter is applied
        return filteredTechnologies.includes(site.technology.toLowerCase()); // return sites based on technology filter
      })
      .map(site => site.siteName) ?? [];
  return uniqueSiteNames;
};

export const getAlertsAfterFilter = (
  alerts: Alert[] | undefined,
  filteredTechnologies: string[],
  filteredSiteNames: string[],
  getSiteById: (siteId: string) => SiteShortConfig | undefined
) => {
  // these are alerts for selected technology and sites
  const filteredAlerts = alerts?.filter(alert => {
    const siteName = getSiteById(alert.siteId)?.siteName;
    if (siteName === undefined) return false;

    const numFilteredTechnologies = filteredTechnologies.length;
    const numFilteredSiteNames = filteredSiteNames.length;

    const noFiltersApplied =
      numFilteredTechnologies === 0 && numFilteredSiteNames === 0;
    if (noFiltersApplied) return true;

    if (numFilteredTechnologies === 0) {
      return filteredSiteNames.includes(siteName);
    } else if (numFilteredSiteNames === 0) {
      return filteredTechnologies.includes(alert.technology.toLowerCase());
    }

    return (
      filteredSiteNames.includes(siteName) &&
      filteredTechnologies.includes(alert.technology.toLowerCase())
    );
  });
  return filteredAlerts;
};
